import { LineCRUDInterface, TaskFilterCRUDInterface } from '../../../../shared/component/filter/filter.class';
import {
  LineAvailabilityPlanDataInterface,
  LineAvailabilityPlanHistoryDataInterface,
  LineAvailabilityPlanItemInterface,
  SchedulerShiftAlternatePlanInterface,
} from '../../../line-availability/line-availability.model';
import { SimplifiedDataInterface } from '../../tasks/tasks.model';
import { LineTypeInterface } from '../../../site-line-selection/site-line-selection.model';
import { TrueFalse } from '../../../../../constants';
import {
  BaseCrudResponse,
  BaseOneResponseInterface,
} from '../../../../shared/model/interface/crud-response-interface.model';
import { ExcelDropdownInterface } from '../../users/users.model';
import { ILayout, ILayoutAssignment } from '../../home-page-display-settings/home-page-display-settings.model';
import { ILookupData } from '../../../lookups/lookups.model';
import { DownloadExcelFiltersInterface } from '../../../../shared/service/excel/excel-helper.service';

export interface LinesStateInterface {
  linesDataLoaded: boolean;
  linesDataLoading: boolean;
  lines: ILines[];
  linesTotal: number;
  shiftPlansDataLoaded: boolean;
  shiftPlansDataLoading: boolean;
  shiftPlans: LineAvailabilityPlanDataInterface[];
  layoutsDataLoaded: boolean;
  layoutsDataLoading: boolean;
  layouts: ILayout[];
  lineExcelDownloading: boolean;
  lineExcelDownloaded: boolean;
  lineExcelUploading: boolean;
  lineExcelUploaded: boolean;
  excelUploadResult: LineExcelInterface[];
  linesCountLoaded: boolean;
  linesCountLoading: boolean;
  lineCountForExcel: number;
  lineAddLoading: boolean;
  lineAddLoaded: boolean;
  lineEditLoading: boolean;
  lineEditLoaded: boolean;
  lineDeleteLoading: boolean;
  lineDeleteLoaded: boolean;
  lineBulkDeleteLoading: boolean;
  lineBulkDeleteLoaded: boolean;
  lineBulkEditLoading: boolean;
  lineBulkEditLoaded: boolean;
  tasksDataLoaded: boolean;
  tasksDataLoading: boolean;
  tasks: TaskFilterCRUDInterface[];
}

export interface ILines extends LineCRUDInterface {
  siteName?: string;
  layoutName?: string;
  layoutAssignment?: ILayoutAssignment;
  shiftPlan?: LineAvailabilityPlanDataInterface;
  schedulerShiftPlanHistory?: LineAvailabilityPlanHistoryDataInterface[];
  departmentId?: number;
  departmentName?: string;
  shiftPlanName?: string;
  isAutomatedPhaseCommentsActive: number;
  isAutomatedScheduledPhaseDurationUpdateActive: boolean;
  isAutomatedScheduledRunDurationUpdateActive: boolean;
  isShowTaskTargetExceedErrorMessage?: boolean;
  oeeFormula: string;
  countEntryMethod: number;
  manualCountType: number;
}

export interface AddEditLineResponseInterface extends BaseOneResponseInterface<LineCRUDInterface> {}

export interface LineExcelInterface {
  distributeOrderedQty: boolean;
  distributeOrderedQtyDropdown?: ExcelDropdownDataWithDescriptionFormatted;
  distributeOrderedQuantity?: boolean;
  id: number;
  microstopSensorType: string;
  microstopSensorTypeDropdown?: ExcelDropdownDataWithDescription;
  title: string;
  siteId: number;
  activityNames: string;
  activityIds: string;
  statusId: number;
  description: string;
  lineType: number;
  unit: number;
  standardSpeed: string;
  shiftPlanId: number;
  automatedPhaseComments: number;
  automatedPhaseDuration: boolean;
  targetExceedMessage: boolean;
  manualCountType: number;
  countEntryMethod: number;
  oeeFormula: string;
  layoutId?: number;
  isAutomatedPhaseCommentsActive?: number;
  automatedRunDuration: number;
  isAutomatedScheduledRunDurationUpdateActive?: number;
  isAutomatedScheduledPhaseDurationUpdateActive?: boolean;
  isShowTaskTargetExceedErrorMessage?: boolean;
  siteDropdown?: SimplifiedDataInterface;
  lineTypesDropdown?: LineTypeInterface;
  shiftPlansDropdown?: ShiftPlanInterface;
  layoutAssignment?: ILayout;
  statusDropdown?: ExcelDropdownDataWithDescription;
  oeeFormulaDropdown?: ExcelDropdownDataWithDescription;
  layoutsDropdown?: ExcelDropdownDataWithDescription;
  countEntryDropdown?: ExcelDropdownDataWithDescription;
  countTypeDropdown?: ExcelDropdownDataWithDescription;
  automatedPhaseCommentsDropdown?: ExcelDropdownDataWithDescriptionFormatted;
  automatedRunDurationDropdown?: ExcelDropdownDataWithDescriptionFormatted;
  automatedPhaseDurationDropdown?: ExcelDropdownDataWithDescriptionFormatted;
  targetExceedMessageDropdown?: ExcelDropdownDataWithDescriptionFormatted;
  workOrderSplitActiveDropdown?: ExcelDropdownInterface[];
  isWorkOrderSplitActive?: boolean;
  workOrderTakeoverBySplitActiveDropdown?: ExcelDropdownInterface[];
  isWorkOrderTakeoverBySplitActive?: boolean;
  distributionMethodOfOrderedQuantity?: number | null;
  distMethodOfOrdQty?: number | null;
  distMethodOfOrdQtyDropdown?: ExcelDropdownDataWithDescription;
  isAutoCheckOutActiveDropdown?: ExcelDropdownDataWithDescriptionFormatted;
  isAutoCheckOutActiveOnLine?: number | null;
  isAutoCheckOutAct?: number | null;
  highlightZeroCountOnProduction?: ExcelDropdownDataWithDescriptionFormatted;
  planEffectiveDate?: string;
}

export interface ExcelDropdownDataWithDescriptionFormatted {
  id: TrueFalse;
  description: string;
}

export interface ExcelDropdownDataWithDescription {
  id: number;
  description: string;
}

export interface IExcelDropdownWithStringId {
  id: string;
  description: string;
}

export interface LinesExcelDropdownContentInterface {
  distributeOrderedQty: IExcelDropdownWithStringId[];
  microstopSensorType: IExcelDropdownWithStringId[];
  site: IExcelSiteDataInterface;
  lineTypes: LineTypeInterface[];
  layouts: SimplifiedDataInterface[];
  shiftPlans: ShiftPlanInterface[];
  statuses: ExcelDropdownDataWithDescription[];
  oeeFormula: IExcelDropdownWithStringId[];
  countEntries: ExcelDropdownDataWithDescription[];
  countTypes: ExcelDropdownDataWithDescription[];
  automatedPhaseComments: IExcelDropdownWithStringId[];
  automatedRunDuration: IExcelDropdownWithStringId[];
  automatedPhaseDuration: IExcelDropdownWithStringId[];
  isMissingDataAttention: IExcelDropdownWithStringId[];
  targetExceedMessage: IExcelDropdownWithStringId[];
  distMethodOfOrdQty: ExcelDropdownDataWithDescription[];
  isAutoCheckOutAct: IExcelDropdownWithStringId[];
  unitTypes: ILookupData[];
  highlightZeroCount: IExcelDropdownWithStringId[];
}

export interface LinesExcelDataInterface {
  distributeOrderedQty?: string;
  microstopSensorType?: string;
  distributeOrderedQuantity?: boolean;
  id?: number;
  siteId: number;
  title: string;
  activityIds?: number[];
  statusId: number;
  standardSpeed: string;
  oeeFormula: string;
  layoutId?: number;
  countEntryMethod: number;
  manualCountType: number;
  shiftPlanId: number;
  automatedPhaseComments?: string;
  isAutomatedPhaseCommentsActive?: boolean;
  automatedRunDuration?: string;
  isAutomatedScheduledRunDurationUpdateActive?: boolean;
  automatedPhaseDuration?: string;
  isAutomatedScheduledPhaseDurationUpdateActive?: boolean;
  targetExceedMessage?: string;
  isShowTaskTargetExceedErrorMessage?: boolean;
  lineType: number;
  description?: string;
  activityNames?: string;
  workOrderSplitActive?: string;
  isWorkOrderSplitActive?: boolean;
  isWorkOrderTakeoverBySplitActive?: boolean;
  woTakeoverBySplit?: boolean;
  isMissingDataAttention?: string;
  meta?: any;
  laborCapacity?: number;
  planEffectiveDate?: string;
  distMethodOfOrdQty?: number | null;
  distributionMethodOfOrderedQuantity?: number | null;
  isAutoCheckOutAct?: string;
  isAutoCheckOutActiveOnLine?: boolean;
  bufferTimeForAutoCheckOut?: number | null;
  excludeTimeForAutoCheckOut?: number | null;
  unit: number;
  highlightZeroCount?: boolean | null;
  highlightZeroCountOnProduction?: boolean | null;
}

export interface ShiftPlanInterface {
  id: number;
  isEditable: true;
  isOfficial: true;
  name: string;
  officialEditable: boolean;
  schedulerShiftPlansSiteAssignment: {
    id: number;
    schedulerShiftPlanId: number;
    siteId: number;
  };
  schedulerShiftPlanItem: LineAvailabilityPlanItemInterface[];
  schedulerShiftAlternatePlan?: SchedulerShiftAlternatePlanInterface[];
}

export interface ShiftPlanItemsInterface {
  schedulerShiftId: number;
  schedulerShiftPlanId: number;
  schedulerShiftExceptionId: number;
  weekDay: number;
  startHour: string;
  endHour: string;
  exceptionDate: string;
}

export interface GetManyShiftPlanItemsCrudResponse extends BaseCrudResponse {
  data?: ShiftPlanItemsInterface[];
  count?: number;
  page?: number;
  total?: number;
  pageCount?: number;
}

export interface LineBulkResponseInterface {
  success: boolean;
  error?: any;
}

export interface ILineExcelResponse {
  siteData: SimplifiedDataInterface[];
  linesData: { lines: LinesExcelDataInterface[] };
}

export interface IExcelSiteDataInterface {
  id: number;
  name: string;
  isNewShiftMechanismActive?: boolean;
}

export enum EDistributionMethodOfOrderedQuantity {
  ORDERED_QUANTITY_AS_GOOD_COUNT_IF_ACTUAL_COUNT_ZERO = 1,
  ORDERED_QUANTITY_AS_INITIAL_COUNT_OW_ACTUAL_COUNT = 2,
  ORDERED_QUANTITY_AS_GOOD_COUNT_OW_ACTUAL_COUNT = 3,
}

export interface ILineExcel extends DownloadExcelFiltersInterface {
  unitTypes: ILookupData[];
}
