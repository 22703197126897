import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewChildDecorator,
} from '@angular/core';
import { WorkOrderManualCountService } from '../../../../shared/service/http/home/work-order-manual-count/work-order-manual-count.service';
import * as AppActions from '../../../../store/app/actions';
import * as ManualCountActions from '../../../../store/work-order-manual-count/actions';
import { ActionsSubject, Store } from '@ngrx/store';
import { OeeAppState } from '../../../../store/oee.reducer';
import { TranslateService } from '@ngx-translate/core';
import {
  dateStringToDateObject,
  getCurrentDateTimeAsMoment,
  mysqlDateFormat,
  mysqlTimestampFormat,
  mysqlTimestampFormatZeroMinAndSec,
  mysqlTimestampFormatZeroSecond,
} from '../../../../shared/helper/date';
import * as moment from 'moment';
import { Subject, Subscription, zip } from 'rxjs';
import {
  CompareData,
  ConvertedCountEntriesInterface,
  CountEntriesInterface,
  CountModalType,
  EInputValidationLimitValues,
  ICheckedInUsers,
  IProductionCountCrudData,
  IDistributionData,
  IDistributionResponse,
  IManualCount,
  IManualCountResponse,
  IMinManualCount,
  IncrementDistributionMethod,
  IQuantityForm,
  ISetHourlyCount,
  IWorkOrderManualCountComment,
  IWorkOrderManualCountDeleteMany,
  ManualCountModalTypes,
  ManualCountPostDataInterface,
  ManualCountTableData,
  RegularOrHourlyManualCount,
  TQuantityModalType,
} from './work-order-manual-count.model';
import * as TagActions from '../../../../store/settings/tags/tags.actions';
import { WorkorderNumbersDataInterface } from '../../../../store/activity-history/activity-history.model';
import { HelperService } from '../../../../shared/service/helper.service';
import { ManualCountTypes } from '../../models/line.model';
import * as _ from 'lodash';
import { CheckInModules } from '../../cico/cico.model';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ScwMatButtonGroupButtons } from '../../../../shared/component/scw-mat-ui/scw-mat-button-group/scw-mat-button-group.model';
import { CountEntryMethod } from '../../../../shared/model/enum/count-entry-method';
import { ScwMatInputComponent } from '../../../../shared/component/scw-mat-ui/scw-mat-input/scw-mat-input.component';
import { DetailCardContent } from '../../../reports/work-order-performance/detail-card/detail-card.model';
import {
  DatatableHeaderInterface,
  DatatableOutputParameterInterface,
} from '../../../../shared/component/datatable/datatable.model';
import { ECloseDialogMessage, IManualCountConfiguration } from '../work-order.model';
import { ECountMode, ManualCountModel } from '../../../../store/work-order-manual-count/model';
import { InputLimit } from '../../../../shared/model/enum/input-limit';
import { ECommentType } from '../../../shift-summary/shift-summary.model';
import { DECIMAL_MIN_VALUE, NEGATIVE_DECIMAL_MIN_VALUE, smallModal } from '../../../../../constants';
import { DecimalHelper } from '../../../../shared/helper/decimal/decimal-helper';
import { ofType } from '@ngrx/effects';
import { ScwMatInputRule } from '../../../../shared/component/scw-mat-ui/scw-mat-input/scw-mat-input.model';
import { ScwMatSelectRule } from '../../../../shared/component/scw-mat-ui/scw-mat-select/scw-mat-select.model';
import { ITableQuery } from '../../../settings/departments-lines-stations/lines/lines.model';
import { DatatableInterface } from '../../../../shared/service/datatable/datatable.model';
import { DatatableService } from '../../../../shared/service/datatable/datatable.service';
import { ScwModalSize } from '../../../../shared/component/scw-mat-ui/scw-mat-modal/scw-mat-modal.model';
import { OnDestroyDecorator } from '../../../../shared/decorator/on-destroy-decorator';
import { ETableModalTypes } from 'src/app/shared/service/datatable/datatable.model';
import { filter, take } from 'rxjs/operators';
import { ToastHelperService } from '../../../../shared/service/toast/toast.helper.service';
import {
  ScwMatRadioGroupOption,
  ScwMatRadioGroupRule,
} from '../../../../shared/component/scw-mat-ui/scw-mat-radio-group/scw-mat-radio-group.model';
import { IPeriodicOeeCalculationData } from '../../../../store/reports/periodic-oee-calculation-review/periodic-oee-calculation-review.model';
import { TagCRUDRequestInterface, TagInterface, TagsObjectTypes } from '../../../../store/settings/tags/tags.model';
import * as CommentTypeActions from '../../../../store/comment-types/comment-types.actions';
import { TCommentResponseDto } from '../../../../standalone/comment-feed/comment-feed-modal/comment-feed-modal.model';
import { ITagsDropdown } from '../../../settings/equipment-assets/equipment-types/equipment-types.model';
import { BaseOneResponseInterface } from '../../../../shared/model/interface/crud-response-interface.model';

@OnDestroyDecorator
@Component({
  selector: 'work-order-manual-count',
  providers: [WorkOrderManualCountService],
  templateUrl: './work-order-manual-count.component.html',
  styleUrls: ['./work-order-manual-count.component.scss'],
})
export class WorkOrderManualCountComponent implements OnInit, OnDestroy {
  @ViewChild('unsaved_modal') unsavedChangesModalTemplateRef: ViewChildDecorator;
  @ViewChild('quantity_edit_modal', { static: false }) quantityEditModal: TemplateRef<any>;
  @Input() countMode: ECountMode = ECountMode.calculated;
  @Input() modalType: CountModalType = CountModalType.countEntry;
  @Input() configuration: IManualCountConfiguration;
  @Input() calledFrom: string = 'Home';
  @Input() saveButtonClicked: Subject<void>;
  @Input() cancelButtonClicked: Subject<ECloseDialogMessage>;
  @Input() isOngoingDistributionAvailable: boolean = false;
  @Input() siteId!: number;
  @Input() lineId!: number;

  @Output() setDistributionRequestParams: EventEmitter<IDistributionData> = new EventEmitter<IDistributionData>();
  @Output() private readonly finalCountDirty = new EventEmitter<boolean>();

  public workOrderCard: IManualCountConfiguration = {
    workOrder: null,
    workOrderId: null,
    productId: null,
    productDescription: null,
    start: null,
    end: null,
    activityEnd: null,
    unitName: null,
    shiftId: null,
    isOngoingHour: false,
  };

  public tableData: ManualCountTableData[] = [];
  public tableHeader: DatatableHeaderInterface[] = [];
  public compareTableHeader: DatatableHeaderInterface[] = [];

  private rawManualCounts$: RegularOrHourlyManualCount[] = [];
  private manualCounts$: ManualCountTableData[] = [];
  private hourlyModeTableHeader: DatatableHeaderInterface[] = [];
  private unsavedComments: IManualCount[] = [];
  private allModeTableHeader: DatatableHeaderInterface[] = [];

  public isNegativeScrapCount: boolean = false;
  public minCountEntryValues: IMinManualCount;
  public dateTimeFormat$: string;
  public timeFormat: string = 'LTS';
  public locale$: string;
  private userStoreSubscription: Subscription;
  private timezone$: string;
  private lineId$: number;
  private siteId$: number;
  private manualCountType$: ManualCountTypes;
  private countEntryMethod$: number = CountEntryMethod.CUMULATIVE;
  public goodTotalCount$: string;
  public scrapTotalCount$: string;
  public totalManualCountsAmount$: number = 0;
  public openedFromProductionReview: boolean = false;
  private openedFromActivityHistory: boolean = false;
  private activityHistorySubscription: Subscription;
  private refresherTimeout: NodeJS.Timeout;
  private defaultTagColor: string = '#d8d8d8';
  public showTimestampMismatchWarning: boolean = false;
  public showInvalidatedByApprovedCountWarning: boolean = false;
  public showCountIsDeletedWarning: boolean = false;
  public siteDecimalScaleLimit$: number;
  private unitNameTranslated: string;
  public selectedItem: ManualCountTableData;
  public selectedItemIds: number[] = [];
  public quantityModalRef: NgbModalRef;
  public quantityDeleteModalRef: NgbModalRef;
  public ongoingDistributionModalRef: NgbModalRef;
  public quantityInformationForm: IQuantityForm = {
    timestamp: { isEnabled: true, value: null },
    countEntry1: { isEnabled: true, value: null },
    countEntry2: { isEnabled: true, value: null },
    incrementDistributionMethod: { isEnabled: false, value: null },
    tags: { isEnabled: false, value: null },
    commentMessage: { isEnabled: false, value: null },
  };
  private readonly subscriptions: Subscription[] = [];
  public textMaxLength: string = InputLimit.TEXT_MAX_LENGTH;
  public saveChangedModalRef: NgbModalRef;
  public modalArray: NgbModalRef[] = [];

  public countEntryDefault = [
    {
      required: true,
    },
    this.decimalHelper.getDecimalNonZeroInputRule(DECIMAL_MIN_VALUE),
  ];
  public quantityFormRules: {
    timestamp: ScwMatSelectRule[];
    countEntry1: ScwMatInputRule[];
    countEntry2: ScwMatInputRule[];
    incrementDistributionMethod: ScwMatRadioGroupRule[];
  } = {
    timestamp: [
      {
        required: true,
      },
    ],
    countEntry1: _.cloneDeep(this.countEntryDefault),
    countEntry2: _.cloneDeep(this.countEntryDefault),
    incrementDistributionMethod: [],
  };
  public quantityModalType: TQuantityModalType;
  public now: moment.Moment = moment();
  public addEditWorkOrderCountModalTitle: string;
  public checkboxIdPrefix: string = 'checkbox-item-';
  public ECountMode = ECountMode;
  public isShowResultsWithCommentsOnlyChecked: boolean = false;
  public countModeButtonGroup: ScwMatButtonGroupButtons[] = [
    {
      text: this.translate.instant('main.workOrder.manuelCountHistoryTable.calculatedRecords'),
      value: ECountMode.calculated,
    },
    {
      text: this.translate.instant('main.workOrder.manuelCountHistoryTable.byHourly'),
      value: ECountMode.hourly,
    },
    {
      text: this.translate.instant('main.workOrder.manuelCountHistoryTable.allRecords'),
      value: ECountMode.all,
    },
  ];

  public productCard: DetailCardContent[] = [];
  public goodScrapCountCard: DetailCardContent[] = [];
  public isApprovedCountOfProductionReview: boolean = false;
  public isPastHourOfProductionReview: boolean = false;
  public isCancelledTableRefresh: boolean = false;
  public isCountCompareMode: boolean = false;
  public isDeleteButtonDisabled: boolean = false;
  public tableQuery: ITableQuery = this.workOrderManualCountService.tableQuery;
  private defaultTableQuery: ITableQuery = this.workOrderManualCountService.tableQuery;
  public readonly ETableModalTypes: typeof ETableModalTypes = ETableModalTypes;
  public readonly manualCountModalTypes = ManualCountModalTypes;
  public readonly countModalType = CountModalType;
  public selectableTableDataLength: number = 0;
  private ongoingWoDistribution: IDistributionData;
  private commentOfSelectedItem: TCommentResponseDto | undefined;
  public weekStartDay$: number = null;
  public noRunTimeAvailableErrorMessage: string = '';
  public incrementDistributionMethodOptions: ScwMatRadioGroupOption[] = [
    {
      text: this.translate.instant('main.workOrder.incrementDistributionMethodOptions.runTimes'),
      value: IncrementDistributionMethod.runTimes,
    },
    {
      text: this.translate.instant('main.workOrder.incrementDistributionMethodOptions.lastHour'),
      value: IncrementDistributionMethod.lastHour,
    },
    {
      text: this.translate.instant('main.workOrder.incrementDistributionMethodOptions.allActivities'),
      value: IncrementDistributionMethod.allActivities,
    },
  ];
  public createItemLabelMessage: string = this.translate.instant('tags.create.dropdown.label');
  public tags$: ITagsDropdown[] = [];
  public readonly CountModalType = CountModalType;
  private isWorkOrderManualCountObtained: boolean = false;

  constructor(
    protected workOrderManualCountService: WorkOrderManualCountService,
    private store: Store<OeeAppState>,
    private translate: TranslateService,
    private helperService: HelperService,
    private readonly ngbModal: NgbModal,
    private readonly decimalHelper: DecimalHelper,
    private readonly toastHelper: ToastHelperService,
    private readonly storeActions: ActionsSubject,
    private readonly datatableService: DatatableService,
    private readonly checkBoxElementRef: ElementRef,
  ) {}

  public chooseWhichDataToRetrieve(): void {
    this.selectedItemIds = [];
    this.selectedItem = null;
    this.setTimeoutForSilentRefresh();

    if (this.openedFromProductionReview) {
      this.getSpecifiedHourWorkOrderManualCounts();
    } else {
      this.isWorkOrderManualCountObtained = true;
      this.retrieveWorkOrderCounts();
    }
  }

  ngOnInit(): void {
    this.store.dispatch(CommentTypeActions.loadIfNotLoaded());
    this.finalCountDirty.emit(false);

    this.tableQuery = [CountModalType.finalDistribution, CountModalType.ongoingDistribution].includes(this.modalType)
      ? { ...this.defaultTableQuery, pageSize: 1000 }
      : _.cloneDeep(this.defaultTableQuery);
    this.openedFromActivityHistory = this.calledFrom === 'ActivityHistory';
    this.openedFromProductionReview = this.calledFrom === 'ProductionReview';

    if (this.saveButtonClicked && this.cancelButtonClicked) {
      this.subscriptions.push(
        this.saveButtonClicked.subscribe(() => {
          this.saveWorkOrderManualCountComments();
        }),
        this.cancelButtonClicked.subscribe((cancelAction: ECloseDialogMessage) => {
          if (cancelAction === ECloseDialogMessage.CANCEL_DIALOG) {
            this.showConfirmationModal();
          }
        }),
      );
    }

    this.subscriptions.push(
      this.store.select('user').subscribe((state) => {
        this.dateTimeFormat$ = state.dateTimeFormat;
        this.locale$ = state.locale;
        this.timezone$ = state.timezone;
        this.lineId$ = state.lineId;
        this.siteId$ = state.siteId;
        this.now = moment().tz(this.timezone$);
        this.weekStartDay$ = state.weekStartDay;
        this.compareTableHeader = [
          {
            value: 'timestamp',
            name: this.translate.instant('main.workOrder.manuelCountHistoryTable.timestamp'),
            selected: true,
            sortable: false,
          },
          {
            value: 'countEntry1',
            name: this.translate.instant('main.workOrder.manuelCountHistoryTable.goodCount'),
            selected: true,
            sortable: false,
          },
          {
            value: 'suggestedCountEntry1',
            name: this.translate.instant('main.workOrder.manuelCountHistoryTable.goodCount'),
            selected: true,
            sortable: false,
          },
          {
            value: 'countEntry2',
            name: this.translate.instant('main.workOrder.manuelCountHistoryTable.scrapCount'),
            selected: true,
            sortable: false,
          },
          {
            value: 'suggestedCountEntry2',
            name: this.translate.instant('main.workOrder.manuelCountHistoryTable.scrapCount'),
            selected: true,
            sortable: false,
          },
        ];
        this.hourlyModeTableHeader = [
          {
            value: null,
            name: '',
            sortable: false,
            width: '39px',
          },
          {
            value: 'timestamp',
            name: this.translate.instant('main.workOrder.manuelCountHistoryTable.timestamp'),
            selected: true,
            sortable: false,
          },
          {
            value: 'countEntry1',
            name: this.translate.instant('main.workOrder.manuelCountHistoryTable.goodCount'),
            selected: true,
            sortable: false,
          },
          {
            value: 'countEntry2',
            name: this.translate.instant('main.workOrder.manuelCountHistoryTable.scrapCount'),
            selected: true,
            sortable: false,
          },
          {
            value: 'productionCountCreatedBy',
            name: this.translate.instant('main.workOrder.manuelCountHistoryTable.createdBy'),
            selected: true,
            sortable: false,
            tooltip: this.translate.instant('main.workOrder.manuelCountHistoryTable.manuelCountCreatedByTooltip'),
          },
        ];

        this.allModeTableHeader = [
          {
            value: null,
            name: '',
            sortable: false,
            width: '39px',
          },
          {
            value: 'timestamp',
            name: this.translate.instant('main.workOrder.manuelCountHistoryTable.timestamp'),
            selected: true,
            sortable: false,
          },
          {
            value: 'countEntry1',
            name: this.translate.instant('main.workOrder.manuelCountHistoryTable.goodCount'),
            selected: true,
            sortable: false,
          },
          {
            value: 'countEntry2',
            name: this.translate.instant('main.workOrder.manuelCountHistoryTable.scrapCount'),
            selected: true,
            sortable: false,
          },
          ...(state.isLaborTrackerActive
            ? [
                {
                  value: 'checkedInUsers',
                  name: this.translate.instant('main.workOrder.manuelCountHistoryTable.checkedInUsers'),
                  selected: true,
                  sortable: false,
                },
              ]
            : []),
          {
            value: 'productionCountCreatedBy',
            name: this.translate.instant('main.workOrder.manuelCountHistoryTable.createdBy'),
            selected: true,
            sortable: false,
            tooltip: this.translate.instant('main.workOrder.manuelCountHistoryTable.manuelCountCreatedByTooltip'),
          },
          {
            value: 'comment',
            name: this.translate.instant('shiftSummary.table.headers.comments'),
            selected: true,
            sortable: false,
          },
        ];

        this.applyTableMode();
      }),
      this.storeActions
        .pipe(
          ofType(
            ManualCountActions.SET_HOURLY_COUNT_COMPLETED,
            ManualCountActions.SET_BULK_HOURLY_COUNT_COMPLETED,
            ManualCountActions.SET_HOURLY_COUNT_FAILED,
            ManualCountActions.SET_BULK_HOURLY_COUNT_FAILED,
          ),
        )
        .subscribe((payload: ManualCountActions.SetBulkHourlyCountCompleted) => {
          if (!payload.duringOngoingDistribution || this.modalType === CountModalType.ongoingDistribution) {
            this.chooseWhichDataToRetrieve();
          }
        }),
      this.storeActions
        .pipe(ofType(ManualCountActions.MANUAL_COUNT_GET_DISTRIBUTION_LOADED))
        .subscribe((payload: ManualCountActions.ManualCountGetDistributionLoaded) => {
          if (this.modalType === CountModalType.countEntry) {
            return;
          }

          this.finalCountDirty.emit(true);
          this.isCountCompareMode = true;
          this.applyTableMode();
          let newTotalGood: string = '0';
          let newTotalScrap: string = '0';
          this.showCountIsDeletedWarning =
            this.modalType === CountModalType.ongoingDistribution &&
            payload.response.some((distribution: IDistributionResponse) => distribution.isDeletionDone);
          const compareTableData: CompareData[] = payload.response.map(
            (distribution: IDistributionResponse): CompareData => {
              newTotalGood = this.decimalHelper.add(newTotalGood, distribution.goodCount);
              newTotalScrap = this.decimalHelper.add(newTotalScrap, distribution.scrapCount);
              return {
                timestamp: distribution.timestamp,
                countEntry1: this.decimalHelper.removeTrailingZeros(
                  this.manualCountType$ === ManualCountTypes.YIELD_AND_SCRAP
                    ? distribution.goodCount
                    : this.decimalHelper.add(distribution.goodCount, distribution.scrapCount),
                ),
                countEntry2: this.decimalHelper.removeTrailingZeros(
                  this.manualCountType$ === ManualCountTypes.INITIAL_AND_YIELD
                    ? distribution.goodCount
                    : distribution.scrapCount,
                ),
                isChanged: distribution.isChanged,
                isDeletionDone: distribution.isDeletionDone,
              };
            },
          );
          this.tableData = this.tableData.map((tableData: ManualCountTableData): ManualCountTableData => {
            const compareRow: CompareData = _.find(compareTableData, { timestamp: tableData.originalTimestamp });
            return {
              ...tableData,
              suggestedCountEntry1: this.decimalHelper.toFixedValue(
                compareRow.countEntry1,
                this.siteDecimalScaleLimit$,
                true,
              ),
              suggestedCountEntry2: this.decimalHelper.toFixedValue(
                compareRow.countEntry2,
                this.siteDecimalScaleLimit$,
                true,
              ),
              isChanged: compareRow.isChanged,
              isDeletionDone: compareRow.isDeletionDone,
            };
          });

          this.isNegativeScrapCount = !!this.tableData.find(
            (tableData: ManualCountTableData) => Number(tableData.countEntry2) < 0,
          );

          this.goodScrapCountCard[0].value = this.decimalHelper.toFixedValue(newTotalGood, this.siteDecimalScaleLimit$);
          this.goodScrapCountCard[1].value = this.decimalHelper.toFixedValue(
            newTotalScrap,
            this.siteDecimalScaleLimit$,
          );

          this.quantityModalRef.close();
        }),
      this.store.select('manualCount').subscribe((payload: ManualCountModel) => {
        if (!payload.isManualCountCommentsSaveStarted && payload.isManualCountCommentsSaveCompleted) {
          if (_.isUndefined(_.find(payload.manualCountCommentSaveResponse, { success: false }))) {
            this.helperService.showToastMessage(
              true,
              this.translate.instant('general.success'),
              this.translate.instant('general.changesSavedSuccessfully'),
            );
          } else {
            this.helperService.showToastMessage(
              false,
              this.translate.instant('general.failed'),
              this.translate.instant('general.error'),
            );
          }

          this.chooseWhichDataToRetrieve();
          this.cancelButtonClicked.next(ECloseDialogMessage.CLOSE_DIALOG);
        }
      }),
      this.storeActions.pipe(ofType(ManualCountActions.MANUAL_COUNT_SET_DISTRIBUTION_LOADED)).subscribe(() => {
        if (this.modalType === CountModalType.finalDistribution) {
          return;
        } else if (this.modalType === CountModalType.countEntry) {
          this.chooseWhichDataToRetrieve();
          return;
        }

        this.toastHelper.showToastMessage(
          true,
          this.translate.instant('general.success'),
          this.translate.instant('general.changesSavedSuccessfully'),
        );
      }),
      this.storeActions
        .pipe(ofType(ManualCountActions.GET_OEE_FOR_DISTRIBUTION_VALIDATION_LOADED))
        .subscribe((payload: ManualCountActions.GetOeeForDistributionValidationLoaded) => {
          const totalDuration: number = payload.oeeData.data.reduce(
            (sum: number, oee: IPeriodicOeeCalculationData) => sum + oee.calculationValues.runTimeDuration,
            0,
          );

          if (totalDuration === 0) {
            this.noRunTimeAvailableErrorMessage = this.translate.instant(
              'main.workOrder.incrementDistributionRunTimeUnavailableError',
            );
            this.incrementDistributionMethodOptions.find(
              (option: ScwMatRadioGroupOption) => option.value === IncrementDistributionMethod.runTimes,
            ).disabled = true;
            this.quantityInformationForm.incrementDistributionMethod.value = IncrementDistributionMethod.lastHour;
            this.store.dispatch(new AppActions.HideLoader());
            return;
          }

          this.submitDistribution();
        }),
      zip([
        this.storeActions.pipe(ofType(ManualCountActions.GET_SCRAP_COUNT_COMMENT_OF_PRODUCTION_COUNT_LOADED)),
        this.storeActions.pipe(
          ofType(TagActions.TagsActionTypes.TagsLoaded),
          filter((payload: TagActions.TagsLoaded) => payload.forInitialLoad),
        ),
      ]).subscribe(
        ([scrapCountCommentPayload, tagsPayload]: [
          ManualCountActions.GetScrapCountCommentOfProductionCountLoaded,
          TagActions.TagsLoaded,
        ]) => {
          this.store.dispatch(new AppActions.HideLoader());
          this.commentOfSelectedItem = _.head(scrapCountCommentPayload.response.data);
          this.tags$ = tagsPayload.payload.data.map((tag) => ({
            id: tag.id,
            name: tag.name,
            backgroundColor: tag.color,
            optionText: tag.name,
          }));
          this.openQuantityModal(this.quantityEditModal, ETableModalTypes.edit);
        },
      ),
      this.storeActions
        .pipe(
          ofType(TagActions.TagsActionTypes.TagsLoaded),
          filter((payload: TagActions.TagsLoaded) => !payload.forInitialLoad),
        )
        .subscribe((payload: TagActions.TagsLoaded) => {
          this.tags$ = HelperService.cloneDeep(payload.payload.data).map((tag: TagInterface) => {
            return {
              id: tag.id,
              name: tag.name,
              backgroundColor: tag.color,
              optionText: tag.name,
            };
          });
        }),

      this.storeActions
        .pipe(ofType(TagActions.TagsActionTypes.CreateTagCompleted))
        .subscribe((payload: { payload: BaseOneResponseInterface<TagCRUDRequestInterface> }) => {
          const tag: ITagsDropdown = {
            id: payload.payload.data.id,
            name: payload.payload.data.name,
            backgroundColor: payload.payload.data.color,
            optionText: payload.payload.data.name,
          };

          this.quantityInformationForm.tags.value = [
            ...this.quantityInformationForm.tags.value,
            HelperService.cloneDeep(tag),
          ];
          this.store.dispatch(
            new TagActions.LoadTags({
              additionalCustomSearch: [
                {
                  siteId: { $eq: this.siteId$ },
                },
                {
                  objectType: { $eq: TagsObjectTypes.ScrapCountComment },
                },
              ],
            }),
          );
        }),
      this.storeActions
        .pipe(ofType(ManualCountActions.SCRAP_COUNT_COMMENT_SAVED, ManualCountActions.DELETE_SCRAP_COUNT_COMMENT_DONE))
        .subscribe(() => {
          this.fetchCountsAfterSuccessfulCrudOperation();
        }),
    );

    if (this.configuration) {
      this.workOrderCard.workOrderId = this.configuration.workOrderId;
      this.workOrderCard.workOrder = this.configuration.workOrder;
      this.workOrderCard.productId = this.configuration.productId;
      this.workOrderCard.productDescription = this.configuration.productDescription;
      this.workOrderCard.start = this.configuration.start;
      this.workOrderCard.end = this.configuration.end;
      this.workOrderCard.activityEnd = this.configuration.activityEnd;
      this.workOrderCard.unitName = this.configuration.unitName;
      this.workOrderCard.shiftId = this.configuration.shiftId;
      this.isPastHourOfProductionReview = this.openedFromProductionReview && !this.configuration.isOngoingHour;

      this.unitNameTranslated = this.translate.instant(`main.workOrder.${this.workOrderCard.unitName}`);
      this.prepareKpiCards();
    }

    if (this.openedFromActivityHistory) {
      this.activityHistorySubscription = this.store
        .select('activityHistoryStore')
        .pipe(take(1))
        .subscribe((state) => {
          this.initializeActivityHistoryTotalCounts(state.workOrders);
        });
    }

    if (!this.isWorkOrderManualCountObtained) {
      this.chooseWhichDataToRetrieve();
    }
  }

  private setTimeoutForSilentRefresh(isPauseIndefinite: boolean = false): void {
    const aMinuteInMS: number = 60000;
    const timeoutDuration: number = isPauseIndefinite ? 1000000000 : aMinuteInMS;
    clearTimeout(this.refresherTimeout);
    this.refresherTimeout = setTimeout(() => {
      this.quantityModalRef?.close();
      this.quantityDeleteModalRef?.close();
      this.saveChangedModalRef?.close();
      this.chooseWhichDataToRetrieve();
    }, timeoutDuration);
  }

  initializeActivityHistoryTotalCounts(workOrders: WorkorderNumbersDataInterface[]): void {
    for (const workOrder of workOrders) {
      if (workOrder.id === this.configuration.workOrderId) {
        this.chooseWhichDataToRetrieve();
        break;
      }
    }
  }

  public setOngoingWoDistribution(manualCounts: IDistributionData) {
    this.ongoingWoDistribution = manualCounts;
  }

  public onOngoingDistributionSubmit(): void {
    this.store.dispatch(
      new ManualCountActions.ManualCountSetDistributionLoading({
        ...this.ongoingWoDistribution,
        doApproveOngoingShiftHour: false,
      }),
    );
  }

  public closeOngoingDistributionModal(): void {
    this.ongoingDistributionModalRef?.close();
    this.chooseWhichDataToRetrieve();
  }

  public openOngoingWoDistributionModal(modalRef: TemplateRef<any>) {
    this.setTimeoutForSilentRefresh(true);
    this.ongoingDistributionModalRef = this.ngbModal.open(modalRef, {
      keyboard: false,
      backdrop: 'static',
      windowClass: 'scw-modal-xl scw-modal-all-scrollable',
    });
  }

  ngOnDestroy(): void {
    clearTimeout(this.refresherTimeout);
    this.store.dispatch(new ManualCountActions.ResetManualCountStore());
    this.subscriptions.forEach((subs: Subscription) => subs.unsubscribe());

    if (this.userStoreSubscription) {
      this.userStoreSubscription.unsubscribe();
    }

    if (this.activityHistorySubscription) {
      this.activityHistorySubscription.unsubscribe();
    }

    this.modalArray.forEach((modal: NgbModalRef) => {
      modal?.close();
    });
  }

  private formatManualCountData(
    manualCountInfo: Pick<IManualCountResponse, 'productionCounts'>,
  ): Pick<IManualCountResponse, 'productionCounts'> {
    let count: number = 0;
    this.isApprovedCountOfProductionReview =
      this.openedFromProductionReview &&
      manualCountInfo.productionCounts.find((item: RegularOrHourlyManualCount) => item.isApproved) !== undefined;

    for (const item of manualCountInfo.productionCounts) {
      item.checkedInUsers = _.uniqBy(item.checkedInUsers, 'id');
      let createdBy: string[] = item.productionCountCreatedBy ? item.productionCountCreatedBy.split(',') : [];
      item.originalTimestamp = _.clone(item.timestamp);
      item.timestamp = this.helperService.convertFromISOFormatToGivenTimezone(item.timestamp);

      if (this.countMode === ECountMode.hourly) {
        item.timestamp = this.getHourlyTimestampFormat(item.timestamp);
        count = count + 1;
        item.id = count;
      } else {
        item.timestamp = this.helperService.setUserDateTimeFormat(item.timestamp, true);
      }

      item.activityExistsOnTimestamp =
        this.countMode === ECountMode.hourly || item.isSystem ? 1 : item.activityExistsOnTimestamp;

      if (item.isSystem === 1) {
        const system: string = this.translate.instant('general.system');
        createdBy = this.countMode === ECountMode.hourly ? createdBy.concat([system]) : [system];
      }

      item.productionCountCreatedBy = createdBy.join(', ');
      item.checkedInUsers = WorkOrderManualCountComponent.formatCheckInUsers(item.checkedInUsers as ICheckedInUsers[]);
    }

    this.prepareKpiCards();
    return manualCountInfo;
  }

  private retrieveWorkOrderCounts(): void {
    this.isCancelledTableRefresh = false;
    this.store.dispatch(new AppActions.ShowLoader());

    const parameters: string[] = [
      ...(this.lineId === -1 ? ['ignoreMissingLineId=1'] : [`lineId=${this.lineId || this.lineId$}`]),
      `pageLimit=${this.tableQuery.pageSize}`,
      `pageOffset=${(this.tableQuery.page - 1) * this.tableQuery.pageSize}`,
      `onlyCommentedRecords=${String(this.isShowResultsWithCommentsOnlyChecked)}`,
      `mode=${this.countMode}`,
    ];

    this.workOrderManualCountService
      .getAll(`/${this.workOrderCard.workOrderId}?${parameters.join('&')}`)
      .then((data: IManualCountResponse) => {
        data.productionCounts = this.getShiftNamesRemovedResults(
          data.productionCounts,
          this.countMode === ECountMode.hourly,
        );
        this.ongoingDistributionModalRef?.close();
        this.assignDataResponseIntoVariables(data);
        this.renderTableData(data.productionCounts);
        this.store.dispatch(new AppActions.HideLoader());
        this.showTimestampMismatchWarning = data.productionCounts.some(
          (manualCount: RegularOrHourlyManualCount) =>
            manualCount.activityExistsOnTimestamp !== 1 && manualCount.isSystem === 0,
        );
        this.showInvalidatedByApprovedCountWarning = data.productionCounts.some(
          (manualCount: RegularOrHourlyManualCount) => _.get(manualCount, 'isInvalidatedByApproved') === 1,
        );
        this.showCountIsDeletedWarning = false;
      });
  }

  private renderTableData(data: RegularOrHourlyManualCount[]): void {
    this.setDistributionRequestParams.emit({
      goodCount: this.goodTotalCount$,
      scrapCount: this.scrapTotalCount$,
      workOrderId: this.workOrderCard.workOrderId,
    });
    const formattedData: Pick<IManualCountResponse, 'productionCounts'> = this.formatManualCountData({
      productionCounts: data,
    });
    this.prepareQuantitiesData(formattedData.productionCounts);
    this.setQuantityTableItemData();
    this.setupCountTypeColumnNames();
  }

  private getSpecifiedHourWorkOrderManualCounts(): void {
    this.store.dispatch(new AppActions.ShowLoader());
    const parameters: string[] = [
      ...(this.lineId === -1 ? ['ignoreMissingLineId=1'] : [`lineId=${this.lineId || this.lineId$}`]),
      `startDate=${this.helperService.convertFromGivenTimezoneToUTCAsISOFormat(this.workOrderCard.start)}`,
      `endDate=${this.helperService.convertFromGivenTimezoneToUTCAsISOFormat(this.workOrderCard.end)}`,
      `pageLimit=${this.tableQuery.pageSize}`,
      `pageOffset=${(this.tableQuery.page - 1) * this.tableQuery.pageSize}`,
      `onlyCommentedRecords=${String(this.isShowResultsWithCommentsOnlyChecked)}`,
      `mode=${ECountMode.all}`,
    ];

    if (this.workOrderCard.shiftId) {
      parameters.push(`shiftId=${this.workOrderCard.shiftId}`);
    }

    this.workOrderManualCountService
      .getAll(`/${this.workOrderCard.workOrderId}?${parameters.join('&')}`)
      .then((data) => {
        data.productionCounts = this.getShiftNamesRemovedResults(data.productionCounts, true);
        this.assignDataResponseIntoVariables(data);
        this.renderTableData(data.productionCounts);

        this.setQuantityTableItemData();
        this.setupCountTypeColumnNames();
        this.ongoingDistributionModalRef?.close();
        this.store.dispatch(new AppActions.HideLoader());
        this.showTimestampMismatchWarning = data.productionCounts.some(
          (manualCount: RegularOrHourlyManualCount) =>
            manualCount.activityExistsOnTimestamp !== 1 && manualCount.isSystem === 0,
        );
        this.showInvalidatedByApprovedCountWarning = data.productionCounts.some(
          (manualCount: RegularOrHourlyManualCount) => _.get(manualCount, 'isInvalidatedByApproved') === 1,
        );
        this.showCountIsDeletedWarning = false;
      });
  }

  public onCreateConfirm(isValid: boolean): void {
    if (isValid) {
      const postData: ManualCountPostDataInterface = this.calculatePostDataForAddEdit();
      postData.timestamp = this.helperService.convertFromGivenTimezoneToUTCAsISOFormat(postData.timestamp);

      this.store.dispatch(new AppActions.ShowLoader());
      this.workOrderManualCountService
        .create(this.workOrderCard.workOrderId, postData)
        .then((response) => {
          this.saveOrDeleteScrapCountComment(response.data.id);
        })
        .catch(() => {
          this.chooseWhichDataToRetrieve();
        });
      this.store.dispatch(new ManualCountActions.SetManualCountFormSubmitted(false));
      this.quantityModalRef.close();
    } else {
      this.store.dispatch(new ManualCountActions.SetManualCountFormSubmitted(true));
    }
  }

  calculateCountTotalAndDelta(newData: CountEntriesInterface): ConvertedCountEntriesInterface {
    let output: ConvertedCountEntriesInterface;
    let entry1 = this.decimalHelper.sanitizeString(newData.entry1);
    let entry2 = this.decimalHelper.sanitizeString(newData.entry2);

    if (this.countEntryMethod$ === CountEntryMethod.CUMULATIVE && this.quantityModalType === ETableModalTypes.add) {
      entry1 = this.decimalHelper.subtract(entry1, this.minCountEntryValues.countEntry1);
      entry2 = this.decimalHelper.subtract(entry2, this.minCountEntryValues.countEntry2);
    }

    switch (this.manualCountType$) {
      case ManualCountTypes.YIELD_AND_SCRAP:
        output = {
          goodCount: entry1,
          scrapCount: entry2,
        };
        break;

      case ManualCountTypes.INITIAL_AND_YIELD:
        output = {
          goodCount: entry2,
          scrapCount: this.decimalHelper.subtract(entry1, entry2),
        };
        break;

      case ManualCountTypes.INITIAL_AND_SCRAP:
        output = {
          goodCount: this.decimalHelper.subtract(entry1, entry2),
          scrapCount: entry2,
        };
        break;
    }

    return {
      goodCount: output.goodCount,
      scrapCount: output.scrapCount,
    };
  }

  private getDateToSubmit(): moment.Moment {
    const shiftStart = dateStringToDateObject(this.workOrderCard.start);
    const shiftEnd = dateStringToDateObject(this.workOrderCard.end);
    const activityEnd = dateStringToDateObject(this.workOrderCard.activityEnd);
    let dateToSubmit = this.workOrderCard.end ? moment(this.workOrderCard.end).subtract(1, 'second') : this.now;

    if (activityEnd < shiftEnd) {
      dateToSubmit = moment(this.workOrderCard.activityEnd).subtract(1, 'second');

      if (activityEnd === shiftStart) {
        dateToSubmit = moment(this.workOrderCard.activityEnd);
      }
    }

    if (
      dateToSubmit.hours() === this.now.hours() &&
      dateToSubmit.format(mysqlDateFormat) === this.now.format(mysqlDateFormat) &&
      this.now.minutes() < dateToSubmit.minutes()
    ) {
      dateToSubmit = this.now;
    }

    return dateToSubmit;
  }

  public onDeleteConfirm(): void {
    this.store.dispatch(new AppActions.ShowLoader());

    if (this.countMode === ECountMode.hourly) {
      const manualCounts: ISetHourlyCount[] = [];

      for (const quantityId of this.selectedItemIds) {
        const item: ManualCountTableData = this.manualCounts$.find(
          (item: ManualCountTableData) => item.id === quantityId,
        );

        manualCounts.push({
          timestamp: this.helperService.convertFromGivenTimezoneToUTCAsISOFormat(item.originalTimestamp),
          workOrderId: this.workOrderCard.workOrderId,
          lineId: this.lineId || this.lineId$,
          goodCount: '0',
          scrapCount: '0',
        });
      }

      this.store.dispatch(
        new ManualCountActions.SetBulkHourlyCount(manualCounts, this.modalType === CountModalType.ongoingDistribution),
      );
      this.quantityDeleteModalRef.close();
      return;
    }

    const deletedWorkOrderManualCounts: IWorkOrderManualCountDeleteMany = {
      productionCounts: [],
    };
    this.selectedItemIds.forEach((manualCount: any) => {
      deletedWorkOrderManualCounts.productionCounts.push({
        productionCountId: manualCount,
        workOrderId: this.workOrderCard.workOrderId,
      });

      _.remove(this.unsavedComments, { id: manualCount });
    });

    if (this.selectedItemIds.length === 1) {
      this.workOrderManualCountService.delete(this.workOrderCard.workOrderId, this.selectedItem.id).then(() => {
        this.chooseWhichDataToRetrieve();
      });
    } else {
      this.workOrderManualCountService.deleteMany(deletedWorkOrderManualCounts).then(() => {
        this.chooseWhichDataToRetrieve();
      });
    }

    this.quantityDeleteModalRef.close();
  }

  private calculatePostDataForAddEdit(): ManualCountPostDataInterface {
    const countTotalAndDelta = this.calculateCountTotalAndDelta({
      entry1: this.quantityInformationForm.countEntry1.value,
      entry2: this.quantityInformationForm.countEntry2.value,
    });

    const isApprovedCount =
      (this.quantityModalType === ETableModalTypes.edit ||
        this.quantityModalType === ManualCountModalTypes.setHourly) &&
      this.selectedItemIds.length === 1 &&
      this.selectedItem?.isApproved === 1;

    const postData: ManualCountPostDataInterface = {
      workOrderId: this.workOrderCard.workOrderId,
      timestamp: moment(this.quantityInformationForm.timestamp.value.startDate).format(mysqlTimestampFormatZeroSecond),
      goodCount: countTotalAndDelta.goodCount,
      scrapCount: countTotalAndDelta.scrapCount,
      lineId: this.lineId || this.lineId$,
    };

    if (isApprovedCount) {
      postData.timestamp = moment(this.quantityInformationForm.timestamp.value.startDate).format('YYYY-MM-DD HH:mm:59');
    } else {
      const activityStartDate: moment.Moment = moment(this.workOrderCard.start);
      const diffBetweenManualAndActivity: moment.Duration = moment.duration(activityStartDate.diff(postData.timestamp));

      if (
        diffBetweenManualAndActivity.hours() === 0 &&
        diffBetweenManualAndActivity.minutes() === 0 &&
        diffBetweenManualAndActivity.seconds() > 0
      ) {
        postData.timestamp = moment(postData.timestamp)
          .add(diffBetweenManualAndActivity.seconds(), 'seconds')
          .format(mysqlTimestampFormat);
      }
    }

    return this.addSystemCountIntoPostData(postData);
  }

  public onEditConfirm(event): void {
    const postData: ManualCountPostDataInterface = this.calculatePostDataForAddEdit();
    const shiftId: number = this.workOrderCard?.shiftId;

    this.store.dispatch(new AppActions.ShowLoader());
    this.quantityModalRef.close();
    postData.timestamp = this.helperService.convertFromGivenTimezoneToUTCAsISOFormat(postData.timestamp);

    this.workOrderManualCountService
      .update(this.workOrderCard.workOrderId, event, { ...postData, shiftId })
      .then((response: BaseOneResponseInterface<IProductionCountCrudData>) => {
        this.saveOrDeleteScrapCountComment(response.data.id);
      })
      .catch(() => {
        this.chooseWhichDataToRetrieve();
      });
  }

  public onQuantityCheckboxChanged(event: MatCheckboxChange): void {
    this.setTimeoutForSilentRefresh();
    const inputValue = Number(event.source.value);

    if (event.checked) {
      this.selectedItemIds.push(inputValue);
    } else {
      const index = this.selectedItemIds.indexOf(inputValue);
      this.selectedItemIds.splice(index, 1);
    }

    this.selectedItem = _.find(this.manualCounts$, { id: this.selectedItemIds[0] });

    this.isDeleteButtonDisabled =
      !!this.manualCounts$.filter((manualCount: RegularOrHourlyManualCount) => {
        return manualCount.isSystem && this.selectedItemIds.includes(manualCount.id);
      }).length && this.countMode !== ECountMode.hourly;
  }

  public openQuantityModal(modalRef: TemplateRef<any>, type: TQuantityModalType): void {
    this.quantityModalType = this.getQuantityModalType(type);
    this.quantityFormRules.countEntry2[1] =
      this.manualCountType$ !== ManualCountTypes.INITIAL_AND_YIELD
        ? this.decimalHelper.getDecimalNonZeroInputRule(NEGATIVE_DECIMAL_MIN_VALUE, undefined, {
            allowNegative: true,
          })
        : this.decimalHelper.getDecimalNonZeroInputRule(DECIMAL_MIN_VALUE);
    this.now = getCurrentDateTimeAsMoment(this.timezone$);

    this.minCountEntryValues = {
      countEntry1:
        this.manualCountType$ === ManualCountTypes.YIELD_AND_SCRAP
          ? this.goodTotalCount$
          : this.decimalHelper.add(this.goodTotalCount$, this.scrapTotalCount$),
      countEntry2:
        this.manualCountType$ === ManualCountTypes.INITIAL_AND_YIELD ? this.goodTotalCount$ : this.scrapTotalCount$,
    };
    this.setTimeoutForSilentRefresh();

    if (this.quantityModalType !== ETableModalTypes.edit) {
      this.commentOfSelectedItem = null;
    }

    switch (this.quantityModalType) {
      case ETableModalTypes.add:
        this.addEditWorkOrderCountModalTitle = this.translate.instant('observationModal.title.workOrderQuantity');
        this.prepareFormToAdd();
        break;
      case ETableModalTypes.edit:
        this.addEditWorkOrderCountModalTitle = this.translate.instant('observationModal.title.editWorkOrderQuantity');
        this.prepareFormToEdit(this.selectedItem);
        break;
      case ManualCountModalTypes.setHourly:
        this.addEditWorkOrderCountModalTitle = this.translate.instant('observationModal.title.editWorkOrderQuantity');
        this.prepareFormToHourly(this.isPastHourOfProductionReview && type === ETableModalTypes.add);
        break;
      case ManualCountModalTypes.setFinal:
      case ManualCountModalTypes.ongoingDistribution:
        this.isCancelledTableRefresh = true;
        this.addEditWorkOrderCountModalTitle = this.translate.instant(
          this.quantityModalType === ManualCountModalTypes.ongoingDistribution
            ? 'main.workOrder.manuelCountHistoryTable.setCount'
            : 'main.workOrder.manuelCountHistoryTable.setFinalCount',
        );
        this.prepareFormToSetFinal();
        break;
      default:
    }

    this.showOrHideScrapCountCommentInputs();

    this.quantityModalRef = this.ngbModal.open(modalRef, {
      keyboard: false,
      backdrop: 'static',
      windowClass: `${ScwModalSize.small} scw-modal-all-scrollable`,
    });
  }

  public openDeleteModal(modalRef: TemplateRef<any>): void {
    this.setTimeoutForSilentRefresh();
    this.quantityDeleteModalRef = this.ngbModal.open(modalRef, {
      keyboard: false,
      backdrop: 'static',
      windowClass: `${ScwModalSize.small} scw-modal-all-scrollable`,
    });
  }

  public prepareFormToAdd(): void {
    if (this.countEntryMethod$ === CountEntryMethod.INCREMENTAL) {
      this.minCountEntryValues.countEntry1 = '0';
      this.minCountEntryValues.countEntry2 = '0';
    }

    const observedAt = this.getDateToSubmit();

    this.quantityInformationForm = {
      timestamp: {
        isEnabled: this.isEnabledTimestampField(),
        value: { startDate: observedAt, endDate: observedAt },
        rules: [],
      },
      countEntry1: {
        isEnabled: true,
        value: this.decimalHelper.formatBySeparator(this.minCountEntryValues?.countEntry1, false),
        rules: [],
      },
      countEntry2: {
        isEnabled: true,
        value: this.decimalHelper.formatBySeparator(this.minCountEntryValues?.countEntry2, false),
        rules: [],
      },
      incrementDistributionMethod: {
        isEnabled: false,
        value: null,
        rules: [],
      },
      tags: {
        isEnabled: false,
        value: [],
        rules: [],
      },
      commentMessage: {
        isEnabled: false,
        value: null,
        rules: [],
      },
    };
  }

  public prepareFormToEdit(selectedItem: RegularOrHourlyManualCount): void {
    this.quantityInformationForm = {
      timestamp: {
        isEnabled: this.isEnabledTimestampField(),
        value: {
          startDate: moment(selectedItem.timestamp, this.dateTimeFormat$, this.locale$),
          endDate: moment(selectedItem.timestamp, this.dateTimeFormat$, this.locale$),
        },
        rules: [],
      },
      countEntry1: {
        isEnabled: true,
        value: this.decimalHelper.formatBySeparator(selectedItem.countEntry1, false),
        rules: [],
      },
      countEntry2: {
        isEnabled: true,
        value: this.decimalHelper.formatBySeparator(selectedItem.countEntry2, false),
        rules: [],
      },
      incrementDistributionMethod: {
        isEnabled: false,
        value: null,
        rules: [],
      },
      tags: {
        isEnabled: true,
        value: this.tags$.filter((tagOption: ITagsDropdown) =>
          (_.get(this.commentOfSelectedItem, 'tags', []) as number[]).includes(tagOption.id),
        ),
        rules: [],
      },
      commentMessage: {
        isEnabled: true,
        value: this.commentOfSelectedItem?.commentMessage,
        rules: [],
      },
    };
  }

  public prepareFormToHourly(ignoreSelectedItem: boolean = false): void {
    const observerAt: string | moment.Moment =
      this.selectedItem && !ignoreSelectedItem
        ? this.helperService.setUserDateTimeFormat(
            this.helperService.convertFromISOFormatToGivenTimezone(this.selectedItem.originalTimestamp),
            true,
          )
        : this.getDateToSubmit();

    this.quantityInformationForm = {
      timestamp: {
        isEnabled: this.isEnabledTimestampField(),
        value: {
          startDate: moment(observerAt, this.dateTimeFormat$, this.locale$),
          endDate: moment(observerAt, this.dateTimeFormat$, this.locale$),
        },
        rules: [],
      },
      countEntry1: {
        isEnabled: true,
        value: this.decimalHelper.formatBySeparator(
          (!ignoreSelectedItem && this.selectedItem?.countEntry1) || this.minCountEntryValues?.countEntry1 || '0',
          false,
        ),
        rules: [],
      },
      countEntry2: {
        isEnabled: true,
        value: this.decimalHelper.formatBySeparator(
          (!ignoreSelectedItem && this.selectedItem?.countEntry2) || this.minCountEntryValues?.countEntry2 || '0',
          false,
        ),
        rules: [],
      },
      incrementDistributionMethod: {
        isEnabled: false,
        value: null,
        rules: [],
      },
      tags: {
        isEnabled: false,
        value: null,
        rules: [],
      },
      commentMessage: {
        isEnabled: false,
        value: null,
        rules: [],
      },
    };
  }

  public prepareFormToSetFinal(): void {
    const observerAt: moment.Moment = this.getDateToSubmit();

    this.quantityInformationForm = {
      timestamp: {
        isEnabled: this.isEnabledTimestampField(),
        value: {
          startDate: moment(observerAt, this.dateTimeFormat$, this.locale$),
          endDate: moment(observerAt, this.dateTimeFormat$, this.locale$),
        },
        rules: [],
      },
      countEntry1: {
        isEnabled: true,
        value: this.decimalHelper.formatBySeparator(this.minCountEntryValues?.countEntry1 || '0', false),
        rules: [],
      },
      countEntry2: {
        isEnabled: true,
        value: this.decimalHelper.formatBySeparator(this.minCountEntryValues?.countEntry2 || '0', false),
        rules: [],
      },
      incrementDistributionMethod: {
        isEnabled: false,
        value: null,
        rules: [],
      },
      tags: {
        isEnabled: false,
        value: null,
        rules: [],
      },
      commentMessage: {
        isEnabled: false,
        value: null,
        rules: [],
      },
    };
  }

  public onSubmit(isValid: boolean): void {
    if (!isValid) {
      return;
    }

    switch (this.quantityModalType) {
      case ETableModalTypes.add:
        this.onCreateConfirm(isValid);
        break;
      case ETableModalTypes.edit:
        if (this.selectedItem.isSystem) {
          this.onCreateConfirm(isValid);
          return;
        }

        this.onEditConfirm(this.selectedItem.id);
        break;
      case ManualCountModalTypes.setFinal:
      case ManualCountModalTypes.ongoingDistribution:
        if (this.quantityInformationForm.incrementDistributionMethod.value !== IncrementDistributionMethod.runTimes) {
          this.submitDistribution();
          return;
        }

        this.setTimeoutForSilentRefresh();
        this.store.dispatch(
          new ManualCountActions.GetOeeForDistributionValidationLoading(this.configuration.workOrderId),
        );
        break;
      case ManualCountModalTypes.setHourly:
        this.onSetHourlyConfirm();
        break;
      default:
        return;
    }
  }

  public onCountEntryInputChange(inputFieldsToValidate: ScwMatInputComponent[] = []): void {
    this.checkInputRules(inputFieldsToValidate);
    this.showOrHideIncrementDistributionMethod();
    this.showOrHideScrapCountCommentInputs();
  }

  private showOrHideIncrementDistributionMethod(): void {
    if (this.modalType === CountModalType.countEntry) {
      return;
    }

    const countEntry1Sanitized: string = this.decimalHelper.sanitizeString(
      this.quantityInformationForm.countEntry1.value,
    );
    const countEntry2Sanitized: string = this.decimalHelper.sanitizeString(
      this.quantityInformationForm.countEntry2.value,
    );

    const doesCountEntry1UpdateIncrease: boolean =
      this.manualCountType$ === ManualCountTypes.YIELD_AND_SCRAP
        ? this.decimalHelper.isGreaterThan(countEntry1Sanitized, this.minCountEntryValues?.countEntry1)
        : this.decimalHelper.isGreaterThan(
            this.decimalHelper.subtract(countEntry1Sanitized, countEntry2Sanitized),
            this.decimalHelper.subtract(this.minCountEntryValues?.countEntry1, this.minCountEntryValues?.countEntry2),
          );

    const isIncrementDistributionRequired =
      doesCountEntry1UpdateIncrease ||
      this.decimalHelper.isGreaterThan(countEntry2Sanitized, this.minCountEntryValues?.countEntry2);

    if (isIncrementDistributionRequired) {
      this.quantityInformationForm.incrementDistributionMethod.isEnabled = true;
      this.quantityInformationForm.incrementDistributionMethod.rules = [{ required: true }];

      if (!this.quantityInformationForm.incrementDistributionMethod.value) {
        this.quantityInformationForm.incrementDistributionMethod.value = this.incrementDistributionMethodOptions.find(
          (option: ScwMatRadioGroupOption) => option.value === IncrementDistributionMethod.runTimes,
        ).disabled
          ? IncrementDistributionMethod.lastHour
          : IncrementDistributionMethod.runTimes;
      }
    } else {
      this.quantityInformationForm.incrementDistributionMethod.isEnabled = false;
      this.quantityInformationForm.incrementDistributionMethod.rules = [{ required: false }];
    }
  }

  private showOrHideScrapCountCommentInputs(): void {
    this.quantityInformationForm.commentMessage.isEnabled =
      (this.quantityModalType === ETableModalTypes.add ||
        (this.quantityModalType === ETableModalTypes.edit &&
          !this.selectedItem?.isApproved &&
          !this.selectedItem?.isSystem)) &&
      this.decimalHelper.isGreaterThan(
        this.calculateCountTotalAndDelta({
          entry1: this.quantityInformationForm.countEntry1.value,
          entry2: this.quantityInformationForm.countEntry2.value,
        }).scrapCount,
        '0',
      );

    this.setTimeoutForSilentRefresh(this.quantityInformationForm.commentMessage.isEnabled);
  }

  private submitDistribution(): void {
    clearTimeout(this.refresherTimeout);
    this.selectedItemIds = [];
    this.selectedItem = null;
    const { workOrderId, goodCount, scrapCount } = this.calculatePostDataForAddEdit();
    const distributionRequestParameters: IDistributionData = {
      workOrderId,
      goodCount,
      scrapCount,
      ...(this.quantityInformationForm.incrementDistributionMethod.isEnabled
        ? { incrementDistributionMethod: this.quantityInformationForm.incrementDistributionMethod.value }
        : {}),
    };
    this.store.dispatch(new ManualCountActions.ManualCountGetDistributionLoading(distributionRequestParameters));
    this.setDistributionRequestParams.emit(distributionRequestParameters);
  }

  private checkInputRules(inputFieldsToValidate: ScwMatInputComponent[]): void {
    switch (this.manualCountType$) {
      case ManualCountTypes.INITIAL_AND_YIELD: {
        this.validateAddModalInitialCountField();
        this.validateAddModalYieldCountField('countEntry2');
        break;
      }
      case ManualCountTypes.INITIAL_AND_SCRAP: {
        this.validateAddModalInitialCountField();
        this.validateAddModalScrapCountField();
        break;
      }
      case ManualCountTypes.YIELD_AND_SCRAP: {
        this.validateAddModalYieldCountField();
        this.validateAddModalScrapCountField();
        break;
      }
      default: {
        break;
      }
    }

    inputFieldsToValidate.forEach((inputField: ScwMatInputComponent) => {
      inputField.checkRules();
    });
  }

  private prepareQuantitiesData(data: RegularOrHourlyManualCount[]): void {
    this.manualCounts$ = data.map((item: RegularOrHourlyManualCount): ManualCountTableData => {
      return {
        ...item,
        elementId: `${item.id}-${this.modalType}`,
        isSelected: this.selectedItemIds.indexOf(item.id) !== -1,
        isChanged: false,
        isDeletionDone: false,
        isDisabled: (this.countMode === ECountMode.hourly || item.isSystem === 1) && item.isOngoingHour === 1,
      };
    });
  }

  public closeQuantityEditModal(countEntry1: ScwMatInputComponent, countEntry2: ScwMatInputComponent): void {
    countEntry1.clearErrorMessage();
    countEntry2.clearErrorMessage();
    this.quantityFormRules.countEntry1 = _.cloneDeep(this.countEntryDefault);
    this.quantityFormRules.countEntry2 = _.cloneDeep(this.countEntryDefault);
    this.quantityModalRef.close();
    this.setTimeoutForSilentRefresh();
  }

  public saveWorkOrderManualCountComments(): void {
    const comments: IWorkOrderManualCountComment[] = [];

    this.unsavedComments.forEach((quantityData: IManualCount) => {
      if (!_.isUndefined(_.find(this.manualCounts$, { id: quantityData.id, comment: quantityData.comment }))) {
        return;
      }

      comments.push({
        id: quantityData.commentId,
        workOrderId: quantityData.workOrderId,
        commentDate: this.helperService.nowAsISO(),
        commentMessage: quantityData.comment,
        objectId: quantityData.id,
        commentTypeId: ECommentType.workOrderManualCountComments,
        objectType: 'production_counts',
        siteId: this.siteId || this.siteId$,
        objectProperty: undefined,
      });
    });

    if (!comments.length) {
      return this.cancelButtonClicked.next(ECloseDialogMessage.CLOSE_DIALOG);
    }

    this.store.dispatch(new ManualCountActions.SaveManualCountComments(comments));
  }

  private setQuantityTableItemData(): void {
    this.setupCountTypeColumnNames();
    this.tableData = _.cloneDeep(this.manualCounts$).map((quantityTableItem: RegularOrHourlyManualCount) => {
      const currentUserCommentInfo: IManualCount = _.find(this.unsavedComments, { id: quantityTableItem.id });

      if (!currentUserCommentInfo) {
        return {
          ...quantityTableItem,
          countEntry1: this.decimalHelper.toFixedValue(
            quantityTableItem.countEntry1.toString(),
            this.siteDecimalScaleLimit$,
          ),
          countEntry2: this.decimalHelper.toFixedValue(
            quantityTableItem.countEntry2.toString(),
            this.siteDecimalScaleLimit$,
          ),
        };
      }

      return {
        ...quantityTableItem,
        countEntry1: this.decimalHelper.toFixedValue(
          quantityTableItem.countEntry1.toString(),
          this.siteDecimalScaleLimit$,
        ),
        countEntry2: this.decimalHelper.toFixedValue(
          quantityTableItem.countEntry2.toString(),
          this.siteDecimalScaleLimit$,
        ),
        comment: currentUserCommentInfo.comment,
        commentId: currentUserCommentInfo.commentId,
      };
    });
    this.isNegativeScrapCount = !!this.tableData.find(
      (tableData: ManualCountTableData) => Number(tableData.countEntry2) < 0,
    );

    this.selectableTableDataLength = this.tableData.filter((item: ManualCountTableData) => !item.isDisabled).length;
  }

  public onChangeCommentModel(event: IManualCount): void {
    this.unsavedComments.push(event);

    this.unsavedComments = _.uniqBy(_.reverse(this.unsavedComments), 'id');
  }

  public showConfirmationModal(): void {
    if (this.unsavedComments.length) {
      this.setTimeoutForSilentRefresh();
      this.saveChangedModalRef = this.ngbModal.open(this.unsavedChangesModalTemplateRef, smallModal);
      this.modalArray.push(this.saveChangedModalRef);

      return;
    }

    this.doActionAfterConfirmation();
  }

  public onCancelConfirmationModel(): void {
    this.saveChangedModalRef?.close();
  }

  public doActionAfterConfirmation(): void {
    this.unsavedComments = [];
    this.saveChangedModalRef?.close();
    this.cancelButtonClicked.next(ECloseDialogMessage.CLOSE_DIALOG);
  }

  private prepareKpiCards(): void {
    this.productCard = [
      {
        label: `${this.workOrderCard.productId} ${this.workOrderCard.productDescription} (${this.translate.instant(
          `main.workOrder.${this.workOrderCard.unitName}`,
        )})`,
        value: this.workOrderCard.workOrder,
        cls: {
          label: 'f-16',
          value: 'f-16',
        },
      },
    ];

    this.goodScrapCountCard = [
      {
        label: this.translate.instant(
          this.modalType === CountModalType.finalDistribution
            ? 'main.workOrder.manuelCountHistoryTable.finalGoodCount'
            : 'main.workOrder.manuelCountHistoryTable.totalGoodCount',
        ),
        value: this.goodTotalCount$
          ? this.decimalHelper.toFixedValue(this.goodTotalCount$, this.siteDecimalScaleLimit$)
          : '0',
        cls: {
          label: 'f-16',
          value: `${this.isApprovedCountOfProductionReview ? 'text-primary' : ''}`,
        },
        icons: [
          {
            value: `${this.isApprovedCountOfProductionReview ? 'text-primary fas fa-check-circle' : ''}`,
          },
        ],
      },
      {
        label: this.translate.instant(
          this.modalType === CountModalType.finalDistribution
            ? 'main.workOrder.manuelCountHistoryTable.finalScrapCount'
            : 'main.workOrder.manuelCountHistoryTable.totalScrapCount',
        ),
        value: this.scrapTotalCount$
          ? this.decimalHelper.toFixedValue(this.scrapTotalCount$, this.siteDecimalScaleLimit$)
          : '0',
        cls: {
          label: 'f-16',
          value: `${this.isApprovedCountOfProductionReview ? 'text-primary' : ''}`,
        },
        icons: [
          {
            value: `${this.isApprovedCountOfProductionReview ? 'text-primary fas fa-check-circle' : ''}`,
          },
        ],
      },
    ];
  }

  public approveOrRejectHourlyCount(approve: boolean): void {
    if (!this.isPastHourOfProductionReview) {
      return;
    }

    const manualCount: ISetHourlyCount = {
      timestamp: this.helperService.convertFromGivenTimezoneToUTCAsISOFormat(
        moment(this.workOrderCard.start).format('YYYY-MM-DD HH:59:59'),
      ),
      workOrderId: this.workOrderCard.workOrderId,
      shiftId: this.workOrderCard.shiftId,
      lineId: this.lineId || this.lineId$,
      goodCount: this.goodTotalCount$,
      scrapCount: this.scrapTotalCount$,
    };

    if (!approve) {
      manualCount.backToSystemCount = true;
      manualCount.goodCount = '0';
      manualCount.scrapCount = '0';
    }

    this.store.dispatch(new ManualCountActions.SetHourlyCount(manualCount));
  }

  public backOriginalCounts(): void {
    this.finalCountDirty.emit(false);
    this.setDistributionRequestParams.emit({
      goodCount: this.goodTotalCount$,
      scrapCount: this.scrapTotalCount$,
      workOrderId: this.workOrderCard.workOrderId,
    });
    this.setTimeoutForSilentRefresh();
    this.renderTableData(_.cloneDeep(this.rawManualCounts$));
    this.isCountCompareMode = false;
    this.applyTableMode();
  }

  public onDataRequestHandler(event: DatatableOutputParameterInterface): void {
    const tableQuery: DatatableInterface = this.datatableService.formatOutputParameters(event);
    this.tableQuery = {
      ...this.tableQuery,
      ...tableQuery,
    };

    this.chooseWhichDataToRetrieve();
  }

  public onTagsSearch(searchText: string): void {
    this.store.dispatch(
      new TagActions.LoadTags({
        search: {
          searchText,
          searchedFields: ['name'],
        },
        additionalCustomSearch: [
          {
            siteId: { $eq: this.siteId$ },
          },
          {
            objectType: { $eq: TagsObjectTypes.ScrapCountComment },
          },
        ],
      }),
    );
  }

  public fetchScrapCommentAndOpenEditModal(quantityEditModal: TemplateRef<any>): void {
    const isScrapCountPositive: boolean =
      this.manualCountType$ === ManualCountTypes.INITIAL_AND_YIELD
        ? this.decimalHelper.isGreaterThan(this.selectedItem.countEntry1, this.selectedItem.countEntry2)
        : this.decimalHelper.isGreaterThan(this.selectedItem.countEntry2, '0');

    if (isScrapCountPositive) {
      this.store.dispatch(new ManualCountActions.GetScrapCountCommentOfProductionCount(this.selectedItem.id));
      this.store.dispatch(
        new TagActions.LoadTags(
          {
            additionalCustomSearch: [
              {
                siteId: { $eq: this.siteId$ },
              },
              {
                objectType: { $eq: TagsObjectTypes.ScrapCountComment },
              },
            ],
          },
          true,
        ),
      );
      return;
    }

    this.commentOfSelectedItem = null;
    this.openQuantityModal(quantityEditModal, ETableModalTypes.edit);
  }

  public onTagCreate($event: string): void {
    const newTag: TagCRUDRequestInterface = {
      siteId: this.siteId$,
      objectType: TagsObjectTypes.ScrapCountComment,
      name: $event.trim(),
      color: this.defaultTagColor,
    };
    this.store.dispatch(new TagActions.CreateTag(newTag));
  }

  private applyTableMode(): void {
    this.tableHeader = _.cloneDeep(this.allModeTableHeader);

    if (this.isCountCompareMode) {
      this.tableHeader = _.cloneDeep(this.compareTableHeader);
    } else if (this.countMode === ECountMode.hourly) {
      this.tableHeader = _.cloneDeep(this.hourlyModeTableHeader);
    }
  }

  private isEnabledTimestampField(): boolean {
    return !(
      this.openedFromProductionReview ||
      this.countMode === ECountMode.hourly ||
      (this.selectedItemIds.length === 1 &&
        (this.selectedItem.isSystem === 1 || this.selectedItem.isApproved === 1) &&
        this.quantityModalType !== ETableModalTypes.add)
    );
  }

  private getHourlyTimestampFormat(timestamp: string): string {
    let startDate: string = moment(timestamp).format(mysqlTimestampFormatZeroMinAndSec);
    let endDate: string = moment(timestamp).add(1, 'hours').format(mysqlTimestampFormatZeroMinAndSec);
    startDate = this.helperService.setUserDateTimeFormat(startDate, true);
    endDate = this.helperService.setUserDateTimeFormat(endDate, true);

    return `${startDate} - ${endDate}`;
  }

  private static formatCheckInUsers(items: ICheckedInUsers[]): string {
    return items
      ?.filter((checkedInUser: ICheckedInUsers) => checkedInUser.id)
      ?.map((checkedInUser: ICheckedInUsers) =>
        HelperService.generateCheckInIcon(checkedInUser.fullName, checkedInUser.fullName, CheckInModules.laborTracker),
      )
      .join(', ');
  }

  private setupCountTypeColumnNames(): void {
    let countEntry1Title: string = this.translate.instant('main.workOrder.manuelCountHistoryTable.goodCount');
    let countEntry2Title: string = this.translate.instant('main.workOrder.manuelCountHistoryTable.scrapCount');
    const suggested: string = this.translate.instant('main.workOrder.manuelCountHistoryTable.suggested');

    if ([ManualCountTypes.INITIAL_AND_YIELD, ManualCountTypes.INITIAL_AND_SCRAP].includes(this.manualCountType$)) {
      countEntry1Title = this.translate.instant('main.workOrder.manuelCountHistoryTable.initialCount');
    }

    if (this.manualCountType$ === ManualCountTypes.INITIAL_AND_YIELD) {
      countEntry2Title = this.translate.instant('main.workOrder.manuelCountHistoryTable.goodCount');
    }

    this.allModeTableHeader[2].name =
      this.hourlyModeTableHeader[2].name =
      this.compareTableHeader[1].name =
        `${countEntry1Title} (${this.unitNameTranslated})`;
    this.compareTableHeader[2].name = `${countEntry1Title} ${suggested} (${this.unitNameTranslated})`;
    this.allModeTableHeader[3].name =
      this.hourlyModeTableHeader[3].name =
      this.compareTableHeader[3].name =
        `${countEntry2Title} (${this.unitNameTranslated})`;
    this.compareTableHeader[4].name = `${countEntry2Title} ${suggested} (${this.unitNameTranslated})`;

    this.applyTableMode();
  }

  private validateAddModalInitialCountField(): void {
    const field: string = 'countEntry1';
    const comparedField: string = 'countEntry2';
    this.quantityFormRules[field][1] = this.decimalHelper.getDecimalNonZeroInputRule(DECIMAL_MIN_VALUE);

    if (this.isFieldValueEmpty(field)) {
      this.quantityFormRules[field][2] = {
        maxValue: EInputValidationLimitValues.errorMaxValue,
        message: this.translate.instant('main.workOrder.workOrderManuelCountForm.goodCount.errors.empty'),
      };
    } else if (this.isFieldValueNegative(field)) {
      this.quantityFormRules[field][2] = {
        minValue: EInputValidationLimitValues.standardMinValue,
        message: this.translate.instant('general.inputErrorMessages.positive'),
      };
    } else if (
      this.quantityModalType === ETableModalTypes.add &&
      this.countEntryMethod$ === CountEntryMethod.CUMULATIVE &&
      this.isFieldValueLessThanCumulativeValue(field)
    ) {
      this.quantityFormRules[field][2] = {
        maxValue: EInputValidationLimitValues.errorMaxValue,
        message: this.translate.instant('main.workOrder.workOrderManuelCountForm.initialCount.errors.newInitialIsLess'),
      };
    } else if (
      this.manualCountType$ === ManualCountTypes.INITIAL_AND_SCRAP &&
      this.isFieldValueLessThanComparedFieldValue(field, comparedField)
    ) {
      this.quantityFormRules[field][2] = {
        maxValue: EInputValidationLimitValues.errorMaxValue,
        message: this.translate.instant(
          'main.workOrder.workOrderManuelCountForm.scrapCount.errors.scrapMoreThanInitial',
        ),
      };
    } else if (
      this.quantityModalType === ETableModalTypes.add &&
      this.countEntryMethod$ === CountEntryMethod.CUMULATIVE &&
      this.manualCountType$ === ManualCountTypes.INITIAL_AND_SCRAP &&
      this.scrapIncreaseIsGreaterThanInitialIncrease(comparedField, field)
    ) {
      this.quantityFormRules[field][2] = {
        maxValue: EInputValidationLimitValues.errorMaxValue,
        message: this.translate.instant(
          'main.workOrder.workOrderManuelCountForm.scrapCount.errors.scrapMoreThanInitialIncrement',
        ),
      };
    } else {
      this.quantityFormRules[field][2] = this.decimalHelper.getDecimalNonZeroInputRule(DECIMAL_MIN_VALUE);
    }
  }

  private validateAddModalYieldCountField(field: string = 'countEntry1'): void {
    this.quantityFormRules[field][1] = this.decimalHelper.getDecimalNonZeroInputRule(DECIMAL_MIN_VALUE);

    if (this.isFieldValueEmpty(field)) {
      this.quantityFormRules[field][2] = {
        maxValue: EInputValidationLimitValues.errorMaxValue,
        message: this.translate.instant('main.workOrder.workOrderManuelCountForm.goodCount.errors.empty'),
      };
    } else if (this.isFieldValueNegative(field)) {
      this.quantityFormRules[field][2] = {
        minValue: EInputValidationLimitValues.standardMinValue,
        message: this.translate.instant('general.inputErrorMessages.positive'),
      };
    } else if (
      this.quantityModalType === ETableModalTypes.add &&
      this.countEntryMethod$ === CountEntryMethod.CUMULATIVE &&
      this.isFieldValueLessThanCumulativeValue(field)
    ) {
      this.quantityFormRules[field][2] = {
        maxValue: EInputValidationLimitValues.errorMaxValue,
        message: this.translate.instant('main.workOrder.workOrderManuelCountForm.goodCount.errors.newGoodIsLess'),
      };
    } else {
      this.quantityFormRules[field][2] = this.decimalHelper.getDecimalNonZeroInputRule(DECIMAL_MIN_VALUE);
    }
  }

  private validateAddModalScrapCountField(): void {
    const field: string = 'countEntry2';
    const comparedField: string = 'countEntry1';
    this.quantityFormRules[field][1] = this.decimalHelper.getDecimalNonZeroInputRule(
      NEGATIVE_DECIMAL_MIN_VALUE,
      undefined,
      {
        allowNegative: true,
      },
    );

    if (this.isFieldValueEmpty(field)) {
      this.quantityFormRules[field][2] = {
        maxValue: EInputValidationLimitValues.errorMaxValue,
        message: this.translate.instant('main.workOrder.workOrderManuelCountForm.goodCount.errors.empty'),
      };
    } else if (
      this.manualCountType$ === ManualCountTypes.YIELD_AND_SCRAP &&
      this.decimalHelper.isLessThan(
        this.decimalHelper.add(
          this.decimalHelper.sanitizeString(this.quantityInformationForm[field].value),
          this.decimalHelper.sanitizeString(this.quantityInformationForm[comparedField].value),
        ),
        '0',
      )
    ) {
      this.quantityFormRules[field][2] = {
        minValue: EInputValidationLimitValues.standardMinValue,
        message: this.translate.instant(
          'main.workOrder.workOrderManuelCountForm.scrapCount.errors.increaseImpliesNegativeInitial',
        ),
      };
    } else if (
      this.quantityModalType === ETableModalTypes.add &&
      this.countEntryMethod$ === CountEntryMethod.CUMULATIVE &&
      this.manualCountType$ === ManualCountTypes.YIELD_AND_SCRAP &&
      this.scrapReductionIsGreaterThanYieldIncrease(field, comparedField)
    ) {
      this.quantityFormRules[field][2] = {
        maxValue: EInputValidationLimitValues.errorMaxValue,
        message: this.translate.instant(
          'main.workOrder.workOrderManuelCountForm.scrapCount.errors.increaseImpliesNegativeInitial',
        ),
      };
    } else if (
      this.manualCountType$ === ManualCountTypes.INITIAL_AND_SCRAP &&
      this.isFieldValueLessThanComparedFieldValue(comparedField, field)
    ) {
      this.quantityFormRules[field][2] = {
        maxValue: EInputValidationLimitValues.errorMaxValue,
        message: this.translate.instant(
          'main.workOrder.workOrderManuelCountForm.scrapCount.errors.scrapMoreThanInitial',
        ),
      };
    } else if (
      this.quantityModalType === ETableModalTypes.add &&
      this.countEntryMethod$ === CountEntryMethod.CUMULATIVE &&
      this.manualCountType$ === ManualCountTypes.INITIAL_AND_SCRAP &&
      this.scrapIncreaseIsGreaterThanInitialIncrease(field, comparedField)
    ) {
      this.quantityFormRules[field][2] = {
        maxValue: EInputValidationLimitValues.errorMaxValue,
        message: this.translate.instant(
          'main.workOrder.workOrderManuelCountForm.scrapCount.errors.scrapMoreThanInitialIncrement',
        ),
      };
    } else {
      this.quantityFormRules[field][2] = this.decimalHelper.getDecimalNonZeroInputRule(
        NEGATIVE_DECIMAL_MIN_VALUE,
        undefined,
        {
          allowNegative: true,
        },
      );
    }
  }

  private scrapReductionIsGreaterThanYieldIncrease(field: string, comparedField: string): boolean {
    const scrapReduction: string = this.decimalHelper.subtract(
      this.decimalHelper.sanitizeString(this.minCountEntryValues[field]),
      this.decimalHelper.sanitizeString(this.quantityInformationForm[field].value),
    );
    const yieldIncrease: string = this.decimalHelper.subtract(
      this.decimalHelper.sanitizeString(this.quantityInformationForm[comparedField].value),
      this.decimalHelper.sanitizeString(this.minCountEntryValues[comparedField]),
    );

    return this.decimalHelper.isGreaterThan(scrapReduction, yieldIncrease);
  }

  private scrapIncreaseIsGreaterThanInitialIncrease(scrapField: string, initialField: string): boolean {
    const scrapIncrease: string = this.decimalHelper.subtract(
      this.decimalHelper.sanitizeString(this.quantityInformationForm[scrapField].value),
      this.decimalHelper.sanitizeString(this.minCountEntryValues[scrapField]),
    );
    const initialIncrease: string = this.decimalHelper.subtract(
      this.decimalHelper.sanitizeString(this.quantityInformationForm[initialField].value),
      this.decimalHelper.sanitizeString(this.minCountEntryValues[initialField]),
    );

    return this.decimalHelper.isGreaterThan(scrapIncrease, initialIncrease);
  }

  private isFieldValueNegative(field: string): boolean {
    return this.decimalHelper.isLessThan(this.quantityInformationForm[field].value, '0');
  }

  private isFieldValueEmpty(field: string): boolean {
    return this.quantityInformationForm[field].value.trim() === '';
  }

  private isFieldValueLessThanCumulativeValue(field: string): boolean {
    return this.decimalHelper.isLessThan(this.quantityInformationForm[field].value, this.minCountEntryValues[field]);
  }

  private isFieldValueLessThanComparedFieldValue(field: string, comparedField: string): boolean {
    return this.decimalHelper.isLessThan(
      this.quantityInformationForm[field].value,
      this.quantityInformationForm[comparedField].value,
    );
  }

  private onSetHourlyConfirm(): void {
    const data: ManualCountPostDataInterface = this.calculatePostDataForAddEdit();

    this.store.dispatch(
      new ManualCountActions.SetHourlyCount(
        {
          timestamp: this.helperService.convertFromGivenTimezoneToUTCAsISOFormat(data.timestamp),
          workOrderId: data.workOrderId,
          shiftId: this.workOrderCard?.shiftId,
          lineId: data.lineId,
          goodCount: data.goodCount,
          scrapCount: data.scrapCount,
        },
        this.modalType === CountModalType.ongoingDistribution,
      ),
    );

    this.store.dispatch(new ManualCountActions.SetManualCountFormSubmitted(false));
    this.quantityModalRef?.close();
  }

  private getQuantityModalType(type: TQuantityModalType): TQuantityModalType {
    if (this.modalType === CountModalType.ongoingDistribution && type === ManualCountModalTypes.setFinal) {
      return ManualCountModalTypes.ongoingDistribution;
    }

    return (this.isPastHourOfProductionReview && type === ETableModalTypes.add) ||
      (this.countMode === ECountMode.hourly && (type === ETableModalTypes.add || type === ETableModalTypes.edit))
      ? ManualCountModalTypes.setHourly
      : type;
  }

  private addSystemCountIntoPostData(postData: ManualCountPostDataInterface): ManualCountPostDataInterface {
    if (this.countEntryMethod$ !== CountEntryMethod.CUMULATIVE || this.quantityModalType !== ETableModalTypes.add) {
      return postData;
    }

    const sameHourManualCounts: RegularOrHourlyManualCount[] = this.tableData.filter(
      (tableData: RegularOrHourlyManualCount) =>
        !tableData.isSystem && moment(tableData.timestamp).endOf('h').isSame(moment(postData.timestamp).endOf('h')),
    );

    const maxManualCountTimestampWithinPostDataHour: string | undefined = _.maxBy(
      sameHourManualCounts,
      'timestamp',
    )?.timestamp;

    if (
      maxManualCountTimestampWithinPostDataHour !== undefined &&
      postData.timestamp <= maxManualCountTimestampWithinPostDataHour
    ) {
      return postData;
    }

    const sameHourSystemCount: ManualCountTableData | undefined = this.tableData.find(
      (tableData: ManualCountTableData) =>
        tableData.isSystem &&
        moment(tableData.originalTimestamp).endOf('h').isSame(moment(postData.timestamp).endOf('h')),
    );

    return sameHourSystemCount
      ? {
          ...postData,
          goodCount: this.decimalHelper.add(postData.goodCount, sameHourSystemCount.goodCount),
          scrapCount: this.decimalHelper.add(postData.scrapCount, sameHourSystemCount.scrapCount),
        }
      : postData;
  }

  private assignDataResponseIntoVariables(data: IManualCountResponse): void {
    this.siteDecimalScaleLimit$ = data.siteDecimalScaleLimit;
    this.rawManualCounts$ = _.cloneDeep(data.productionCounts);
    this.manualCountType$ = data.manualCountType;
    this.countEntryMethod$ = data.countEntryMethod;
    this.totalManualCountsAmount$ = data.total;
    this.goodTotalCount$ = data.totalGoodCount;
    this.scrapTotalCount$ = data.totalScrapCount;
  }

  private getShiftNamesRemovedResults(
    manualCounts: RegularOrHourlyManualCount[],
    removeAll: boolean,
  ): RegularOrHourlyManualCount[] {
    const resultsGroupedByMinute: { [minute: string]: RegularOrHourlyManualCount[] } = _.groupBy(
      manualCounts,
      (manualCount: RegularOrHourlyManualCount) => manualCount.timestamp.slice(0, 16),
    );
    return manualCounts.map((manualCount: RegularOrHourlyManualCount) => {
      if (removeAll) {
        return _.omit(manualCount, 'shiftName');
      }

      const minuteOfManualCount: string = manualCount.timestamp.slice(0, 16);

      return resultsGroupedByMinute[minuteOfManualCount]?.length > 1 ? manualCount : _.omit(manualCount, 'shiftName');
    });
  }

  private saveOrDeleteScrapCountComment(productionCountId: number): void {
    if (
      this.quantityInformationForm.commentMessage.isEnabled &&
      (this.quantityInformationForm.commentMessage?.value || this.quantityInformationForm.tags?.value.length)
    ) {
      this.saveScrapCountCommentIfUpdated(productionCountId);
      return;
    }

    if (this.commentOfSelectedItem?.id) {
      this.store.dispatch(new ManualCountActions.DeleteScrapCountComment(this.commentOfSelectedItem.id));
      return;
    }

    this.fetchCountsAfterSuccessfulCrudOperation();
  }

  private fetchCountsAfterSuccessfulCrudOperation(): void {
    this.chooseWhichDataToRetrieve();
    this.toastHelper.showToastMessage(
      true,
      this.translate.instant('general.success'),
      this.translate.instant('general.changesSavedSuccessfully'),
    );
  }

  private saveScrapCountCommentIfUpdated(productionCountId: number): void {
    const commentMessageToSubmit: string = this.quantityInformationForm.commentMessage.value ?? '';
    const tagsToSubmit: number[] = (this.quantityInformationForm.tags.value ?? [])
      .map((tagOption: ITagsDropdown) => tagOption.id)
      .sort();
    const isPreviousCommentUpdated: boolean =
      !this.commentOfSelectedItem?.id ||
      commentMessageToSubmit !== this.commentOfSelectedItem.commentMessage ||
      !_.isEqual(this.commentOfSelectedItem.tags.sort(), tagsToSubmit);

    if (!isPreviousCommentUpdated) {
      this.fetchCountsAfterSuccessfulCrudOperation();
      return;
    }

    this.store.dispatch(
      new ManualCountActions.SaveScrapCountComment({
        id: this.commentOfSelectedItem ? this.commentOfSelectedItem.id : null,
        commentMessage: commentMessageToSubmit,
        objectId: productionCountId,
        objectType: 'production_counts',
        tags: tagsToSubmit,
      }),
    );
  }

  public selectOrUnselectAll(isSelectAll: boolean): void {
    if (
      this.countMode !== ECountMode.hourly ||
      this.checkBoxElementRef.nativeElement.querySelector(`[id^="${this.checkboxIdPrefix}"]`) === null
    ) {
      return;
    }

    this.tableData.forEach((item: ManualCountTableData) => {
      const checkbox = _.head(
        document.getElementById(`${this.checkboxIdPrefix}${item.elementId}`)?.getElementsByTagName('input'),
      );

      if ((isSelectAll && !checkbox?.checked) || (!isSelectAll && checkbox?.checked)) {
        checkbox?.click();
      }
    });
  }
}
