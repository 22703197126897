import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import {
  FormattedWorkOrdersResponseInterface,
  GetWorkOrderResponseInterface,
  LineTypeInterface,
  ProductionStagesResponseInterface,
  ProductsInterface,
  ProductsResponseInterface,
  RecalculateResponseInterface,
  SitesInterface,
  WorkOrderEditCreateResponseInterface,
  WorkOrderInterface,
  WorkOrderResponseInterface,
  WorkOrderScheduleSite,
} from '../../../store/work-order-schedule/work-order-schedule.model';
import {
  CustomDependFilterKeysEnum,
  IWorkOrderControlParams,
  IWorkOrderControlResponse,
  WorkOrderDeleteResponseInterface,
} from './work-order-schedule.model';
import * as _ from 'lodash';
import {
  dateTypesConstant,
  workOrderPropertyConstant,
  workOrderStatusConstant,
} from '../../component/filter/filterable-objects.class';
import { Moment } from 'moment';
import { ExcelHelperService } from '../excel/excel.helper.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment-timezone';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../../store/oee.reducer';
import * as ObjectActions from '../../../store/work-order-schedule/work-order-schedule.actions';
import { ValueType, Workbook, Worksheet } from 'exceljs';
import {
  WorkOrderCloseFeedbackInterface,
  WorkOrderCloseFeedbackResponseInterface,
} from '../../../store/work-order/work-order.model';
import { minutesToHm } from '../../helper/date';
import { excelDateFormat, excelTimeFormat } from '../../model/enum/excel-date-format';
import {
  FieldTypes,
  IFilterOutput,
  QueryTypes,
  TargetEndpoints,
} from '../../component/filter/advanced-filter/advanced-filter.model';
import { AdvancedFilterService } from '../../component/filter/advanced-filter/advanced-filter.service';
import {
  BulkEditFieldInterface,
  CustomNewDesignWorkOrdersFilterOutputInterface,
  CustomWorkOrdersFilterOutputInterface,
  NewBulkEditFieldInterface,
  ScenarioWorkOrdersTableQueryParams,
  TableQueryParams,
  WorkOrderBulkSaveManyInterface,
  WorkOrderScheduleSaveOneDtoInterface,
} from '../../../view/work-orders-new-design/work-orders-new-design.model';
import { LineCRUDResponseInterface } from '../filter/service.class';
import { DecimalHelper } from '../../helper/decimal/decimal-helper';
import { SchedulerHelperService } from '../scheduler/scheduler-helper.service';
import { ECellTypes, EExcelColumnWidth, EExcelSheetType } from '../excel/excel.enum';
import { IJob } from '../../../store/settings/jobs/jobs.model';
import { JobsService } from '../../../store/settings/jobs/jobs.service';
import { EExcelDateFormatOption, ICreateExcel, ICreateExcelSheet, IExcelColumnKeys } from '../excel/excel.helper';
import { HTTPHelperService } from '../http/http.helper.service';
import { SiteService } from '../filter/site.service';
import { LineCRUDInterface } from '../../component/filter/filter.class';
import { GenericHelperService } from '../generic.helper.service';
import {
  BaseOneResponseInterface,
  GetManyResponseInterface,
} from '../../model/interface/crud-response-interface.model';
import { LinePathService } from '../line-path/line-path.service';
import { ExcelDropdownInterface } from '../../../store/settings/users/users.model';
import { HelperService } from '../helper.service';

@Injectable({
  providedIn: 'root',
})
export class WorkOrderScheduleService {
  private timezone: string = 'utc';
  private dateFormat$: string;
  private timeFormat$: string;
  private readonly destroySubject: Subject<boolean> = new Subject<boolean>();
  private dateCharacterFormat$: string;

  constructor(
    public http: HttpClient,
    public readonly excelHelper: ExcelHelperService,
    @Inject('API_BASE_URL') private api: string,
    private translate: TranslateService,
    private readonly advancedFilterService: AdvancedFilterService,
    private store: Store<oeeAppReducer.OeeAppState>,
    private readonly decimalHelper: DecimalHelper,
    private readonly schedulerHelperService: SchedulerHelperService,
    private readonly jobsService: JobsService,
    private readonly httpHelper: HTTPHelperService,
    private readonly siteService: SiteService,
    private readonly linePathService: LinePathService,
    public helperService: HelperService,
  ) {
    this.store
      .select('user')
      .pipe(takeUntil(this.destroySubject))
      .subscribe((state) => {
        if (state.isUserLoaded) {
          this.timezone = state.timezone;
          if (state.locale !== '') {
            this.dateCharacterFormat$ = state.dateFormat;
            this.dateFormat$ = excelDateFormat[state.locale !== 'ja' ? state.locale : state.locale + state.dateFormat];
            this.timeFormat$ = excelTimeFormat[state.locale];
          }
          this.destroySubject.next(true);
          this.destroySubject.complete();
        }
      });
  }

  private routes = {
    sites: '/sites',
    lines: '/lines',
    products: '/products',
    productionStages: '/line-types',
    workOrders: '/work-orders',
    customWorkOrders: '/work-orders/get-work-orders-with-oee-summary',
    workOrderCloseFeedback: '/work-order-close-feedbacks',
    assignWoLinePathJob: '/line-path-job-assignment',
    getJobs: '/jobs',
    workOrderMissingControl: '/work-order-missing-control',
  };

  private delete = {
    singleDelete: '/work-orders',
    bulkDelete: '/work-orders/bulk/delete',
  };

  private edit = {
    singleEdit: '/work-orders',
    bulkEdit: '/work-orders/bulk/edit',
  };

  private bulk = {
    save: '/work-orders/bulk/save',
  };

  private recalculate = {
    bulk: '/work-orders/recalculate-work-orders',
  };

  private prepareWorkorderFilters(
    getScenarioSearchParams: any,
    filter: ScenarioWorkOrdersTableQueryParams,
    isWhiteWorkOrderRequest: boolean = false,
  ): string {
    const search: string[] = [
      'join=site||name',
      'join=product||description,packageSize,productId,productFamily,customerId,productFamilyId',
      'join=product.customer',
      'join=actualLineDetail||title',
      'join=scheduledLineDetail||title',
      'join=lineType||lineType',
      'join=job||jobName',
      `page=${filter.page}`,
      `limit=${filter.pageSize}`,
      `s=${getScenarioSearchParams}`,
      'getCounts=true',
    ];

    if (isWhiteWorkOrderRequest) {
      search.push('join=scenarioWorkOrderRules||id,scenarioId,workOrderId,isBlack');
    }

    let searchString = '';
    search.forEach((params: string) => (searchString += `${params}&`));
    if (filter.hasOwnProperty('orderType') && filter.hasOwnProperty('order')) {
      let orderBy: string = filter.order;
      switch (orderBy) {
        case 'productName':
          orderBy = 'product.description';
          break;
        case 'scheduledLineName':
          orderBy = 'scheduledLineDetail.title';
          break;
        case 'actualLineName':
          orderBy = 'actualLineDetail.title';
          break;
      }
      searchString += `sort=${orderBy},${filter.orderType ? 'DESC' : 'ASC'}`;
    } else {
      searchString += 'sort=id,DESC';
    }
    return searchString;
  }

  private getScenarioSearchParams(
    payload: ScenarioWorkOrdersTableQueryParams,
    isWhiteWorkOrderRequest: boolean,
  ): string {
    const searchParamsAdvancedFilter: { [key: string]: any } = { $and: [] };

    if (payload.advancedFilter) {
      const advancedFilter: IFilterOutput[] = payload.advancedFilter.filters;

      for (const filter of advancedFilter) {
        if (filter.queryType !== QueryTypes.withinAdvancedFilterParams) {
          continue;
        }

        if (filter.type === FieldTypes.predefined) {
          searchParamsAdvancedFilter['$and'].push(
            this.advancedFilterService.generateQuery(
              filter.path,
              filter.type,
              filter.operator.name,
              filter.operator.type,
              TargetEndpoints.NestJSCrud,
              filter.value[0][filter.searchBy],
            ),
          );
        } else {
          searchParamsAdvancedFilter['$and'].push(
            this.advancedFilterService.generateQuery(
              filter.path,
              filter.type,
              filter.operator.name,
              filter.operator.type,
              TargetEndpoints.NestJSCrud,
              filter.value,
            ),
          );
        }
      }
    }

    const searchParams: { [key: string]: any } = {
      $and: [
        {
          $and: [
            {
              $or: [
                {
                  completed: {
                    $isnull: true,
                  },
                },
                {
                  completed: {
                    $eq: 0,
                  },
                },
              ],
            },
            {
              canceled: {
                $eq: 0,
              },
            },
            {
              siteId: {
                $eq: payload.siteId,
              },
            },
          ],
        },
        {
          siteId: {
            $eq: payload.siteId,
          },
        },
        isWhiteWorkOrderRequest
          ? {
              $and: [
                {
                  'scenarioWorkOrderRules.isBlack': {
                    $eq: 0,
                  },
                },
                {
                  'scenarioWorkOrderRules.scenarioId': {
                    $eq: payload.scenarioId,
                  },
                },
              ],
            }
          : {
              $and: [...searchParamsAdvancedFilter['$and']],
            },
      ],
    };
    return JSON.stringify(searchParams);
  }

  public checkWorkOrders(woNumber: string, siteId: number): Observable<FormattedWorkOrdersResponseInterface> {
    const url = `${this.api}${this.routes.workOrders}`;
    const filter = {
      $and: [
        {
          woNumber: { $eq: woNumber },
        },
        {
          siteId: { $eq: siteId },
        },
      ],
    };
    let queryParams: HttpParams = new HttpParams();
    queryParams = queryParams.set('s', JSON.stringify(filter));

    return this.http.get<FormattedWorkOrdersResponseInterface>(`${url}`, {
      params: queryParams,
    });
  }

  public getWorkOrder(workOrderId: number, params?: HttpParams): Observable<GetWorkOrderResponseInterface> {
    return this.http.get<GetWorkOrderResponseInterface>(`${this.api}${this.routes.workOrders}/${workOrderId}`, {
      params,
    });
  }

  public getSites(params?: HttpParams): Observable<GetManyResponseInterface<WorkOrderScheduleSite>> {
    return this.http.get<GetManyResponseInterface<WorkOrderScheduleSite>>(`${this.api}${this.routes.sites}`, {
      params,
    });
  }

  public getLines(params?: HttpParams): Observable<LineCRUDResponseInterface> {
    params = params.set('join', 'lineTypeName');

    return this.http.get<LineCRUDResponseInterface>(`${this.api}${this.routes.lines}`, {
      params,
    });
  }

  public getProducts(
    siteId: number,
    searchText: string = null,
    productId: number = null,
    httpParams: HttpParams = new HttpParams(),
  ): Observable<ProductsResponseInterface> {
    const siteFilter = { siteId: { $eq: String(siteId) } };
    const orFilter = { $or: [] };
    let queryParams: HttpParams = httpParams;
    let searchParameterValue = null;

    if (!queryParams.has('limit')) {
      queryParams = queryParams.set('limit', '50');
    }

    if (searchText !== null) {
      orFilter.$or.push(
        { description: { $cont: searchText } },
        { productId: { $cont: searchText } },
        { id: { $eq: productId } },
      );
    }

    if (productId !== null) {
      queryParams = queryParams.set('sort', `id=${productId},DESC`);
      queryParams = queryParams.set('join', 'productVersionInfo');
    }

    if (searchText !== null || productId !== null) {
      searchParameterValue = {
        $and: [orFilter, siteFilter],
      };
      queryParams = queryParams.set('s', JSON.stringify(searchParameterValue));
    } else {
      searchParameterValue = siteFilter;
      queryParams = queryParams.set('s', JSON.stringify(searchParameterValue));
    }

    if (!_.isNil(searchText) && searchText !== '') {
      queryParams = queryParams.append('searchText', String(searchText));
    }

    return this.http.get<ProductsResponseInterface>(`${this.api}${this.routes.products}`, {
      params: queryParams,
    });
  }

  public getProductionStages(params: HttpParams): Observable<ProductionStagesResponseInterface> {
    return this.http.get<ProductionStagesResponseInterface>(`${this.api}${this.routes.productionStages}`, { params });
  }

  public saveWorkOrderAddForm(
    workOrder: Partial<WorkOrderInterface>,
  ): Observable<WorkOrderEditCreateResponseInterface> {
    return this.http.post<WorkOrderEditCreateResponseInterface>(`${this.api}${this.routes.workOrders}`, workOrder);
  }

  public deleteWorkOrder(workOrder: number[] | number): Observable<WorkOrderDeleteResponseInterface> {
    if (Array.isArray(workOrder) && workOrder.length > 1) {
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        body: {
          workOrders: workOrder,
        },
      };
      return this.http.delete<WorkOrderDeleteResponseInterface>(`${this.api}${this.delete.bulkDelete}`, httpOptions);
    }
    return this.http.delete<WorkOrderDeleteResponseInterface>(`${this.api}${this.delete.singleDelete}/${workOrder[0]}`);
  }

  public bulkEditWorkOrder(workOrders: BulkEditFieldInterface[]): Observable<any> {
    if (Array.isArray(workOrders) && workOrders.length > 1) {
      const formattedWorkOrders: BulkEditFieldInterface[] = [];
      let formattedWorkOrder: BulkEditFieldInterface;

      workOrders.forEach((item: BulkEditFieldInterface) => {
        formattedWorkOrder = {
          id: item.id,
        };

        for (const [key, value] of Object.entries(item)) {
          if (key !== 'id') {
            _.set(formattedWorkOrder, key, value.value);
          }
        }

        formattedWorkOrders.push(formattedWorkOrder);
      });

      return this.http.patch<any>(`${this.api}${this.edit.bulkEdit}`, {
        workOrders: formattedWorkOrders,
      });
    }

    return of(undefined);
  }

  public newDesignBulkEditWorkOrder(workOrders: NewBulkEditFieldInterface[]): Observable<any> {
    if (Array.isArray(workOrders) && workOrders.length > 1) {
      const formattedWorkOrders: NewBulkEditFieldInterface[] = [];
      let formattedWorkOrder: NewBulkEditFieldInterface;

      workOrders.forEach((item: NewBulkEditFieldInterface) => {
        formattedWorkOrder = {
          id: item.id,
        };

        for (const [key, value] of Object.entries(item)) {
          if (key !== 'id') {
            _.set(formattedWorkOrder, key, value);
          }
        }

        formattedWorkOrders.push(formattedWorkOrder);
      });

      return this.http.patch<any>(`${this.api}${this.edit.bulkEdit}`, {
        workOrders: formattedWorkOrders,
      });
    }

    return of(undefined);
  }

  public recalculateWorkOrder(workOrders: number[]): Observable<RecalculateResponseInterface> {
    if (Array.isArray(workOrders) && workOrders.length >= 1) {
      return this.http.post<RecalculateResponseInterface>(`${this.api}${this.recalculate.bulk}`, {
        workOrders,
      });
    }

    return of(null);
  }

  public editWorkOrder(workOrder: Partial<WorkOrderInterface>, workOrderId: number): Observable<any> {
    return this.http.patch<any>(`${this.api}${this.edit.singleEdit}/${workOrderId}`, {
      ...workOrder,
    });
  }

  public downloadExcel(
    withData: boolean,
    siteId: number,
    params: HttpParams,
    selectedDownloadOffset: number = 1,
    withErrorColumn: boolean = false,
    data?: any[],
  ): void {
    const workOrderTitle: string = this.translate.instant('workOrderSchedule.listView.headers.workOrder');
    const httpParams: HttpParams = new HttpParams();

    forkJoin({
      site: this.getSitesForExcel(siteId),
      lines: this.getLines(httpParams.set('s', JSON.stringify({ siteId })).set('limit', '500')).pipe(
        map((response) => response.data),
        map((lines) => lines.sort(GenericHelperService.dropdownOptionCompareFunction)),
      ),
      products: this.getProducts(siteId, undefined, undefined, httpParams.set('limit', '15000')).pipe(
        map((response) => response.data),
      ),
      jobs: this.jobsService
        .getJobs(httpParams.set('s', JSON.stringify({ siteId })).set('limit', '15000'))
        .pipe(map((response) => response.data)),
      excelName: this.translate
        .get('excel.workOrderSchedule.excelName', {
          date: moment().tz(this.timezone).format(this.dateFormat$),
        })
        .pipe(map((name): string => name || workOrderTitle)),
      workOrders:
        withData && !data
          ? this.getCustomWorkOrdersNewDesign(params)
          : this.getCustomWorkOrdersNewDesign(
              new HttpParams()
                .set('siteId', String(siteId))
                .append('offset', String(selectedDownloadOffset))
                .append('limit', String(5000)),
            ),
    }).subscribe(({ site, lines, products, jobs, excelName, workOrders }) => {
      site.linePaths = this.linePathService.getFormattedAndSortedLinePathOptions(site.linePaths);
      let excelData: any[] = [];

      if (withData) {
        excelData = workOrders ? this.formatResponse(workOrders, true).data : [];

        if (excelData) {
          if (data) {
            excelData = data;
          }

          const workOrderDepartments: LineTypeInterface[] = lines
            .filter((line) => line.lineTypeName)
            .map((line) => ({ id: line.lineTypeName.id, lineType: line.lineTypeName.lineType }));

          for (const workOrder of excelData) {
            const workOrderScheduledLine = lines.find((line) => line.id === workOrder.scheduledLine);
            const workOrderProduct = products.find((product: ProductsInterface) =>
              data ? product.id === workOrder.productId : product.productId === workOrder.productId,
            );
            const workOrderJob = jobs.find((job: IJob) => job.id === workOrder.jobId);
            const workOrderScheduledLinePath = site.linePaths.find(
              (linePath) => linePath.id === workOrder.scheduledLinePathId,
            );
            const workOrderDepartment: LineTypeInterface = workOrderDepartments.find(
              (department) => department.id === workOrder.departmentId,
            );

            if (site) {
              workOrder.site = site;
            }

            if (workOrderScheduledLine) {
              workOrder.line = workOrderScheduledLine;
            }

            if (workOrderProduct) {
              workOrder.product = workOrderProduct;
            }

            if (workOrderJob) {
              workOrder.job = workOrderJob;
            }

            if (workOrderScheduledLinePath) {
              workOrder.scheduledLinePath = workOrderScheduledLinePath;
            }

            if (workOrderDepartment) {
              workOrder.department = workOrderDepartment;
            }
          }
        }

        const productsOfWorkOrders: ProductsInterface[] = excelData.reduce((filteredData, workOrder) => {
          if (workOrder.productId !== null) {
            filteredData.push({ id: workOrder.productTableId, productId: workOrder.productId });
          }

          return filteredData;
        }, []);

        products = _.unionBy(products, productsOfWorkOrders, 'id');
      }

      const orderedProducts: ProductsInterface[] = _.orderBy(
        products.filter((product: ProductsInterface) => product?.id !== undefined),
        [(product) => String(product?.productId)?.toLowerCase()],
        'asc',
      );

      const excelOptions: ICreateExcel = this.getWorkOrderExcelColumns(
        [site],
        orderedProducts,
        lines,
        jobs,
        withErrorColumn,
        withData,
      );

      if (withData) {
        excelOptions.data = this.formatExcelValues(excelData);
      }

      if (excelName === undefined) {
        excelName = workOrderTitle;
      }

      const worksheets: ICreateExcelSheet[] = [
        {
          withData,
          sheetTitle: workOrderTitle,
          sheetType: EExcelSheetType.TABLE,
          params: excelOptions,
          isDisabledColumnsFirstLine: true,
          addDateTimeFormula: true,
          excelRowFormatLimit: 5001,
        },
      ];
      const { columnSpecificDateFormats } = site;

      this.excelHelper
        .createExcel(
          excelName,
          { withData, name: 'workOrderSchedule', siteId: site?.id },
          worksheets,
          this.timezone,
          this.dateFormat$,
          this.timeFormat$,
          false,
          1,
          columnSpecificDateFormats,
        )
        .then(
          () => {
            this.store.dispatch(new ObjectActions.DownloadExcelCompleted());
          },
          () => {
            this.store.dispatch(new ObjectActions.FetchError({}));
          },
        );
    });
  }

  public uploadExcel(workOrders: WorkOrderBulkSaveManyInterface): Observable<any> {
    if (Array.isArray(workOrders.workOrders) && workOrders.workOrders.length > 0) {
      return this.http.post<any>(`${this.api}/${this.bulk.save}`, workOrders);
    }

    return of(undefined);
  }

  private getWorkOrderSheetColumnKeys(): IExcelColumnKeys {
    const { columns } = this.getWorkOrderExcelColumns([], [], [], [], false);

    return ExcelHelperService.getSheetColumnKeys(columns);
  }

  public async getWorkOrdersFromExcel(
    file: File,
  ): Promise<{ workOrderData: WorkOrderBulkSaveManyInterface; siteData: { id: number; name: string }[] }> {
    const workbook: Workbook = await ExcelHelperService.getExcelWorkBookFromFile(file);

    const workOrderSheet: Worksheet = workbook.getWorksheet(
      this.translate.instant('workOrderSchedule.listView.headers.workOrder'),
    );
    const siteIdDataSheet: Worksheet = workbook.getWorksheet('siteIdDataSheet');
    const productIdDataSheet: Worksheet = workbook.getWorksheet('productIdDataSheet');
    const scheduledLineDataSheet: Worksheet = workbook.getWorksheet('scheduledLineDataSheet');

    if (!workOrderSheet || !siteIdDataSheet || !productIdDataSheet || !scheduledLineDataSheet) {
      return null;
    }

    const siteColumns = {
      id: {
        key: 'id',
        type: ValueType.String,
        dataValidationType: ECellTypes.CUSTOM,
      },
      name: {
        key: 'name',
        type: ValueType.String,
        dataValidationType: ECellTypes.CUSTOM,
      },
    };

    const sites: { id: number; name: string }[] = this.excelHelper.getExcelRowsFromWorkSheet<{
      id: number;
      name: string;
    }>(siteIdDataSheet, siteColumns);

    if (!sites.length) {
      return null;
    }

    const columnKeys: IExcelColumnKeys = this.getWorkOrderSheetColumnKeys();

    return {
      workOrderData: {
        workOrders: this.excelHelper.getExcelRowsFromWorkSheet<WorkOrderScheduleSaveOneDtoInterface>(
          workOrderSheet,
          columnKeys,
          {
            dateFormat: this.dateFormat$,
            timeFormat: this.timeFormat$,
            timezone: this.timezone,
          },
        ),
      },
      siteData: sites,
    };
  }

  private getWorkOrderExcelColumns(
    sites: SitesInterface[],
    products: ProductsInterface[],
    lines: LineCRUDInterface[],
    jobs: IJob[],
    withErrorColumn: boolean,
    withData: boolean = false,
  ): ICreateExcel {
    const isLinePathUtilizedOnSite: boolean = !sites.length || Boolean(sites?.[0]?.linePaths?.length);
    const dateFormula: string = this.excelHelper.getExcelDateFormula(this.dateFormat$, this.dateCharacterFormat$);
    const booleanDropdownOptions: ExcelDropdownInterface[] = this.excelHelper.getExcelBooleanDropdownOptions();
    const workOrderDepartments: LineTypeInterface[] = _.unionBy(
      lines
        .filter((line) => !!line.lineTypeName)
        .map((line) => ({ id: line.lineTypeName.id, lineType: line.lineTypeName.lineType })),
      'id',
    );

    const workOrderExcelColumns: ICreateExcel = {
      columns: [
        {
          header: this.translate.instant('workOrderSchedule.listView.headers.siteName'),
          key: 'siteId',
          width: EExcelColumnWidth.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: sites,
            prop: 'site.name',
            dataProperty: 'site.name',
            dataId: 'site.id',
          },
          dataValidation: {
            type: ECellTypes.LIST,
            allowBlank: false,
            formulae: [],
            showErrorMessage: true,
            error: this.translate.instant('excel.workOrderSchedule.site.error'),
            errorTitle: this.translate.instant('excel.workOrderSchedule.site.errorTitle'),
            errorStyle: 'error',
            showInputMessage: true,
            prompt: this.translate.instant('excel.workOrderSchedule.site.prompt'),
            promptTitle: this.translate.instant('excel.workOrderSchedule.site.promptTitle'),
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('workOrderSchedule.listView.headers.departmentName'),
          key: 'departmentId',
          width: EExcelColumnWidth.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: workOrderDepartments,
            prop: 'department.lineType',
            dataProperty: 'department.lineType',
            dataId: 'department.id',
          },
          dataValidation: {
            type: ECellTypes.LIST,
            formulae: [],
            showErrorMessage: true,
            error: this.translate.instant('excel.workOrderSchedule.departmentName.error'),
            errorTitle: this.translate.instant('excel.workOrderSchedule.departmentName.errorTitle'),
            errorStyle: 'error',
            showInputMessage: true,
            prompt: this.translate.instant('excel.workOrderSchedule.departmentName.prompt'),
            promptTitle: this.translate.instant('excel.workOrderSchedule.departmentName.promptTitle'),
          },
          isRequired: false,
        },
        {
          header: this.translate.instant('workOrderSchedule.listView.headers.workOrder'),
          key: 'woNumber',
          width: EExcelColumnWidth.DEFAULT,
          type: ValueType.String,
          style: { numFmt: '@' },
          dataValidation: {
            type: ECellTypes.CUSTOM,
            allowBlank: false,
            formulae: [],
            showErrorMessage: true,
            error: this.translate.instant('excel.workOrderSchedule.workOrderNumber.error'),
            errorTitle: this.translate.instant('excel.workOrderSchedule.workOrderNumber.errorTitle'),
            errorStyle: 'Error',
            showInputMessage: true,
            prompt: this.translate.instant('excel.workOrderSchedule.workOrderNumber.prompt'),
            promptTitle: this.translate.instant('excel.workOrderSchedule.workOrderNumber.promptTitle'),
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('workOrderSchedule.listView.headers.workOrderId'),
          key: 'id',
          width: EExcelColumnWidth.DEFAULT,
          type: ValueType.String,
          style: { numFmt: '@' },
          dataValidation: {
            type: ECellTypes.CUSTOM,
            allowBlank: false,
            formulae: [],
            showErrorMessage: true,
            error: this.translate.instant('excel.workOrderSchedule.workOrderNumber.error'),
            errorTitle: this.translate.instant('excel.workOrderSchedule.workOrderNumber.errorTitle'),
            errorStyle: 'Error',
            showInputMessage: true,
            prompt: this.translate.instant('excel.workOrderSchedule.workOrderNumber.prompt'),
            promptTitle: this.translate.instant('excel.workOrderSchedule.workOrderNumber.promptTitle'),
          },
        },
        {
          header: this.translate.instant('excel.workOrderSchedule.jobName.header'),
          key: 'jobId',
          width: EExcelColumnWidth.DEFAULT,
          type: ValueType.String,
          style: { numFmt: '@' },
          dropdownOptions: {
            data: jobs,
            prop: 'job.jobName',
            dataProperty: 'job.jobName',
            dataId: 'job.id',
          },
          dataValidation: {
            type: ECellTypes.LIST,
            allowBlank: true,
            showErrorMessage: true,
            error: this.translate.instant('excel.workOrderSchedule.jobName.error'),
            formulae: [],
            errorTitle: this.translate.instant('excel.workOrderSchedule.jobName.errorTitle'),
            errorStyle: 'Error',
            showInputMessage: true,
            promptTitle: this.translate.instant('excel.workOrderSchedule.jobName.promptTitle'),
            prompt: this.translate.instant('excel.workOrderSchedule.jobName.prompt'),
          },
        },
        {
          header: this.translate.instant('excel.workOrderSchedule.sequenceNumber.header'),
          key: 'sequenceNumber',
          width: EExcelColumnWidth.DEFAULT,
          type: ValueType.Number,
          style: { numFmt: '0' },
          maxLength: 11,
          dataValidation: {
            type: ECellTypes.CUSTOM,
            allowBlank: false,
            showErrorMessage: true,
            error: this.translate.instant('excel.workOrderSchedule.sequenceNumber.error'),
            formulae: [],
            errorTitle: this.translate.instant('excel.workOrderSchedule.sequenceNumber.errorTitle'),
            errorStyle: 'Error',
            showInputMessage: true,
            promptTitle: this.translate.instant('excel.workOrderSchedule.sequenceNumber.promptTitle'),
            prompt: this.translate.instant('excel.workOrderSchedule.sequenceNumber.prompt'),
          },
        },
        {
          header: this.translate.instant('general.excel.column.product'),
          key: 'productId',
          width: EExcelColumnWidth.DEFAULT,
          type: ValueType.String,
          style: { numFmt: '@' },
          dropdownOptions: {
            data: products,
            prop: 'product.productId',
            dataProperty: 'product.productId',
            dataId: 'product.id',
          },
          dataValidation: {
            type: ECellTypes.LIST,
            allowBlank: false,
            formulae: [],
            showErrorMessage: true,
            error: this.translate.instant('excel.workOrderSchedule.productId.error'),
            errorTitle: this.translate.instant('excel.workOrderSchedule.productId.errorTitle'),
            errorStyle: 'Error',
            showInputMessage: true,
            promptTitle: this.translate.instant('excel.workOrderSchedule.productId.promptTitle'),
            prompt: this.translate.instant('excel.workOrderSchedule.productId.prompt'),
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('workOrderSchedule.listView.headers.quantityOrdered'),
          key: 'quantityOrdered',
          width: EExcelColumnWidth.DECIMAL,
          type: ValueType.Number,
          style: { numFmt: '0.000000000000000###############' },
          maxLength: 11,
          dataValidation: {
            type: ECellTypes.CUSTOM,
            allowBlank: false,
            showErrorMessage: true,
            error: this.translate.instant('excel.workOrderSchedule.quantityOrdered.error'),
            formulae: [],
            errorTitle: this.translate.instant('excel.workOrderSchedule.quantityOrdered.errorTitle'),
            errorStyle: 'Error',
            showInputMessage: true,
            promptTitle: this.translate.instant('excel.workOrderSchedule.quantityOrdered.promptTitle'),
            prompt: this.translate.instant('excel.workOrderSchedule.quantityOrdered.prompt'),
          },
          isRequired: true,
          isDecimalNumber: true,
        },
        {
          header: this.translate.instant('excel.workOrderSchedule.targetSpeed.label'),
          key: 'woTargetSpeed',
          width: EExcelColumnWidth.DECIMAL,
          type: ValueType.Number,
          style: { numFmt: '0.000000000000000###############' },
          allowPunctuation: true,
          dataValidation: {
            type: ECellTypes.CUSTOM,
            allowBlank: false,
            showErrorMessage: true,
            error: this.translate.instant('excel.workOrderSchedule.speed.error'),
            formulae: [],
            errorTitle: this.translate.instant('excel.workOrderSchedule.speed.errorTitle'),
            errorStyle: 'Error',
            showInputMessage: true,
          },
          isDecimalNumber: true,
        },
        {
          header: this.translate.instant('workOrderSchedule.listView.headers.workOrderDueDate'),
          key: 'woDueDate',
          width: EExcelColumnWidth.DEFAULT,
          type: ValueType.Date,
          style: { numFmt: '@' },
          specificDateFormat: sites[0]?.columnSpecificDateFormats?.['woDueDate'] ?? EExcelDateFormatOption.Datetime,
          dataValidation: {
            type: ECellTypes.CUSTOM,
            allowBlank: false,
            showErrorMessage: true,
            error: this.translate.instant('excel.workOrderSchedule.woDueDate.error'),
            formulae: [null],
            errorTitle: this.translate.instant('excel.workOrderSchedule.woDueDate.errorTitle'),
            errorStyle: 'Error',
            showInputMessage: true,
            promptTitle: this.translate.instant('excel.workOrderSchedule.woDueDate.promptTitle'),
          },
          isRequired: false,
        },
        {
          header: this.translate.instant('workOrderSchedule.listView.headers.releaseDate'),
          key: 'releaseDate',
          width: EExcelColumnWidth.DEFAULT,
          type: ValueType.Date,
          style: { numFmt: '@' },
          dataValidation: {
            type: ECellTypes.CUSTOM,
            allowBlank: false,
            showErrorMessage: true,
            error: this.translate.instant('excel.workOrderSchedule.releaseDate.error'),
            formulae: [dateFormula],
            errorTitle: this.translate.instant('excel.workOrderSchedule.releaseDate.errorTitle'),
            errorStyle: 'Error',
            showInputMessage: true,
            promptTitle: this.translate.instant('excel.workOrderSchedule.releaseDate.promptTitle'),
          },
        },
        {
          header: this.translate.instant('workOrderSchedule.listView.headers.scheduledStartDatetime'),
          key: 'scheduledRunDate',
          width: EExcelColumnWidth.DEFAULT,
          type: ValueType.Date,
          style: { numFmt: '@' },
          specificDateFormat:
            sites[0]?.columnSpecificDateFormats?.['scheduledRunDate'] ?? EExcelDateFormatOption.Datetime,
          dataValidation: {
            type: ECellTypes.CUSTOM,
            allowBlank: false,
            showErrorMessage: true,
            error: this.translate.instant('excel.workOrderSchedule.scheduledRunDate.error'),
            formulae: [null],
            errorTitle: this.translate.instant('excel.workOrderSchedule.scheduledRunDate.errorTitle'),
            errorStyle: 'Error',
            showInputMessage: true,
            promptTitle: this.translate.instant('excel.workOrderSchedule.scheduledRunDate.promptTitle'),
          },
        },
        {
          header: this.translate.instant('workOrderSchedule.listView.headers.scheduledEndDatetime'),
          key: 'scheduledEndDate',
          width: EExcelColumnWidth.DEFAULT,
          type: ValueType.Date,
          style: { numFmt: '@' },
          specificDateFormat:
            sites[0]?.columnSpecificDateFormats?.['scheduledEndDate'] ?? EExcelDateFormatOption.Datetime,
          dataValidation: {
            type: ECellTypes.CUSTOM,
            allowBlank: false,
            showErrorMessage: true,
            error: this.translate.instant('excel.workOrderSchedule.scheduledEndDate.error'),
            formulae: [null],
            errorTitle: this.translate.instant('excel.workOrderSchedule.scheduledEndDate.errorTitle'),
            errorStyle: 'Error',
            showInputMessage: true,
            promptTitle: this.translate.instant('excel.workOrderSchedule.scheduledEndDate.promptTitle'),
          },
        },
        {
          header: this.translate.instant('workOrderSchedule.listView.headers.scheduledDepartment'),
          key: 'scheduledDepartmentTitle',
          width: EExcelColumnWidth.DEFAULT,
          type: ValueType.String,
          style: { numFmt: '@' },
          dataValidation: {
            type: ECellTypes.TEXTLENGTH,
            allowBlank: true,
          },
        },
        {
          header: this.translate.instant('workOrderSchedule.listView.headers.scheduledLine'),
          key: 'scheduledLine',
          width: EExcelColumnWidth.DEFAULT,
          type: ValueType.String,
          style: { numFmt: '@' },
          dropdownOptions: {
            data: lines,
            prop: 'line.title',
            dataProperty: 'line.title',
            dataId: 'line.id',
          },
          dataValidation: {
            type: ECellTypes.LIST,
            allowBlank: false,
            formulae: [],
            showErrorMessage: true,
            error: this.translate.instant('excel.workOrderSchedule.scheduledLine.error'),
            errorTitle: this.translate.instant('excel.workOrderSchedule.scheduledLine.errorTitle'),
            errorStyle: 'Error',
            showInputMessage: true,
            promptTitle: this.translate.instant('excel.workOrderSchedule.scheduledLine.promptTitle'),
            prompt: this.translate.instant('excel.workOrderSchedule.scheduledLine.prompt'),
          },
        },
        ...(isLinePathUtilizedOnSite
          ? [
              {
                header: this.translate.instant('general.dataTable.header.scheduledLinePath'),
                key: 'scheduledLinePathId',
                width: EExcelColumnWidth.DEFAULT,
                type: ValueType.String,
                style: { numFmt: '@' },
                dropdownOptions: {
                  data: sites?.[0]?.linePaths,
                  prop: 'scheduledLinePath.name',
                  dataProperty: 'scheduledLinePath.name',
                  dataId: 'scheduledLinePath.id',
                },
                dataValidation: {
                  type: ECellTypes.LIST,
                  allowBlank: false,
                  formulae: [],
                  showErrorMessage: true,
                  error: this.translate.instant('excel.column.error', {
                    field: this.translate.instant('general.dataTable.header.scheduledLinePath'),
                  }),
                  errorTitle: this.translate.instant('excel.column.errorTitle'),
                  errorStyle: 'Error',
                  showInputMessage: true,
                  promptTitle: this.translate.instant('excel.column.promptDropDownTitle', {
                    field: this.translate.instant('general.dataTable.header.scheduledLinePath'),
                  }),
                  prompt: this.translate.instant('excel.column.promptDropDown', {
                    field: this.translate.instant('general.dataTable.header.scheduledLinePath'),
                  }),
                },
              } as ICreateExcel['columns'][number],
            ]
          : []),
        {
          header: this.translate.instant('excel.workOrderSchedule.scheduledPreDuration.header'),
          key: 'scheduledPreDuration',
          width: EExcelColumnWidth.DECIMAL,
          type: ValueType.Number,
          style: { numFmt: '0.000000000000000###############' },
          allowPunctuation: true,
          dataValidation: {
            type: ECellTypes.CUSTOM,
            allowBlank: false,
            showErrorMessage: true,
            error: this.translate.instant('excel.workOrderSchedule.scheduledPreDuration.error'),
            formulae: [],
            errorTitle: this.translate.instant('excel.workOrderSchedule.scheduledPreDuration.errorTitle'),
            errorStyle: 'Error',
            showInputMessage: true,
            promptTitle: this.translate.instant('excel.workOrderSchedule.scheduledPreDuration.promptTitle'),
            prompt: this.translate.instant('excel.workOrderSchedule.scheduledPreDuration.prompt'),
          },
          isDecimalNumber: true,
        },
        {
          header: this.translate.instant('excel.workOrderSchedule.scheduledRunDuration.header'),
          key: 'scheduledRunDuration',
          width: EExcelColumnWidth.DECIMAL,
          type: ValueType.Number,
          style: { numFmt: '0.000000000000000###############' },
          allowPunctuation: true,
          dataValidation: {
            type: ECellTypes.CUSTOM,
            allowBlank: false,
            showErrorMessage: true,
            error: this.translate.instant('excel.workOrderSchedule.scheduledRunDuration.error'),
            formulae: [],
            errorTitle: this.translate.instant('excel.workOrderSchedule.scheduledRunDuration.errorTitle'),
            errorStyle: 'Error',
            showInputMessage: true,
            promptTitle: this.translate.instant('excel.workOrderSchedule.scheduledRunDuration.promptTitle'),
            prompt: this.translate.instant('excel.workOrderSchedule.scheduledRunDuration.prompt'),
          },
          isDecimalNumber: true,
        },
        {
          header: this.translate.instant('excel.workOrderSchedule.scheduledPostDuration.header'),
          key: 'scheduledPostDuration',
          width: EExcelColumnWidth.DECIMAL,
          type: ValueType.Number,
          style: { numFmt: '0.000000000000000###############' },
          allowPunctuation: true,
          dataValidation: {
            type: ECellTypes.CUSTOM,
            allowBlank: false,
            showErrorMessage: true,
            error: this.translate.instant('excel.workOrderSchedule.scheduledPostDuration.error'),
            formulae: [],
            errorTitle: this.translate.instant('excel.workOrderSchedule.scheduledPostDuration.errorTitle'),
            errorStyle: 'Error',
            showInputMessage: true,
            promptTitle: this.translate.instant('excel.workOrderSchedule.scheduledPostDuration.promptTitle'),
            prompt: this.translate.instant('excel.workOrderSchedule.scheduledPostDuration.prompt'),
          },
          isDecimalNumber: true,
        },
        {
          header: this.translate.instant('workOrderSchedule.listView.headers.salesOrderInformation'),
          key: 'salesOrderInformation',
          width: EExcelColumnWidth.DEFAULT,
          type: ValueType.String,
          style: { numFmt: '@' },
          maxLength: 255,
          dataValidation: {
            type: ECellTypes.CUSTOM,
          },
          isRequired: false,
        },
        {
          header: this.translate.instant('workOrderSchedule.listView.headers.processOrder'),
          key: 'processOrder',
          width: EExcelColumnWidth.DEFAULT,
          type: ValueType.String,
          style: { numFmt: '@' },
          dataValidation: {
            type: ECellTypes.CUSTOM,
            allowBlank: false,
            formulae: [],
            showInputMessage: true,
            prompt: this.translate.instant('excel.workOrderSchedule.processOrder.prompt'),
            promptTitle: this.translate.instant('excel.workOrderSchedule.processOrder.promptTitle'),
          },
        },
        {
          header: this.translate.instant('workOrderSchedule.listView.headers.operationNumber'),
          key: 'operationNumber',
          width: EExcelColumnWidth.DEFAULT,
          type: ValueType.String,
          style: { numFmt: '@' },
          dataValidation: {
            type: ECellTypes.CUSTOM,
            allowBlank: false,
            formulae: [],
            showInputMessage: true,
            prompt: this.translate.instant('excel.workOrderSchedule.operationNumber.prompt'),
            promptTitle: this.translate.instant('excel.workOrderSchedule.operationNumber.promptTitle'),
          },
        },
        {
          header: this.translate.instant('workOrderSchedule.listView.headers.scheduledPreRunLaborHours'),
          key: 'scheduledPreRunLaborHours',
          width: EExcelColumnWidth.DECIMAL,
          type: ValueType.Number,
          style: { numFmt: '0.000000000000000###############' },
          allowPunctuation: true,
          dataValidation: {
            type: ECellTypes.CUSTOM,
            allowBlank: false,
            showErrorMessage: true,
            error: this.translate.instant('excel.workOrderSchedule.scheduledPreRunLaborHours.error'),
            formulae: [],
            errorTitle: this.translate.instant('excel.column.errorTitle'),
            errorStyle: 'Error',
            showInputMessage: true,
            promptTitle: this.translate.instant('excel.workOrderSchedule.scheduledPreRunLaborHours.promptTitle'),
            prompt: this.translate.instant('excel.workOrderSchedule.scheduledPreRunLaborHours.prompt'),
          },
          isDecimalNumber: true,
        },
        {
          header: this.translate.instant('workOrderSchedule.listView.headers.scheduledRunLaborHours'),
          key: 'scheduledRunLaborHours',
          width: EExcelColumnWidth.DECIMAL,
          type: ValueType.Number,
          style: { numFmt: '0.000000000000000###############' },
          allowPunctuation: true,
          dataValidation: {
            type: ECellTypes.CUSTOM,
            allowBlank: false,
            showErrorMessage: true,
            error: this.translate.instant('excel.workOrderSchedule.scheduledRunLaborHours.error'),
            formulae: [],
            errorTitle: this.translate.instant('excel.column.errorTitle'),
            errorStyle: 'Error',
            showInputMessage: true,
            promptTitle: this.translate.instant('excel.workOrderSchedule.scheduledRunLaborHours.promptTitle'),
            prompt: this.translate.instant('excel.workOrderSchedule.scheduledRunLaborHours.prompt'),
          },
          isDecimalNumber: true,
        },
        {
          header: this.translate.instant('workOrderSchedule.listView.headers.scheduledPostRunLaborHours'),
          key: 'scheduledPostRunLaborHours',
          width: EExcelColumnWidth.DECIMAL,
          type: ValueType.Number,
          style: { numFmt: '0.000000000000000###############' },
          allowPunctuation: true,
          dataValidation: {
            type: ECellTypes.CUSTOM,
            allowBlank: false,
            showErrorMessage: true,
            error: this.translate.instant('excel.workOrderSchedule.scheduledPostRunLaborHours.error'),
            formulae: [],
            errorTitle: this.translate.instant('excel.column.errorTitle'),
            errorStyle: 'Error',
            showInputMessage: true,
            promptTitle: this.translate.instant('excel.workOrderSchedule.scheduledPostRunLaborHours.promptTitle'),
            prompt: this.translate.instant('excel.workOrderSchedule.scheduledPostRunLaborHours.prompt'),
          },
          isDecimalNumber: true,
        },
        {
          header: this.translate.instant('workOrderSchedule.listView.headers.minimumWaitingDuration'),
          key: 'minimumWaitingDuration',
          width: EExcelColumnWidth.DECIMAL,
          type: ValueType.Number,
          style: { numFmt: '0.000000000000000###############' },
          allowPunctuation: true,
          dataValidation: {
            type: ECellTypes.CUSTOM,
            allowBlank: false,
            showErrorMessage: true,
            formulae: [],
            errorStyle: 'Error',
            showInputMessage: true,
          },
          isRequired: false,
          isDecimalNumber: true,
        },
        {
          header: this.translate.instant('workOrderSchedule.listView.headers.maximumWaitingDuration'),
          key: 'maximumWaitingDuration',
          width: EExcelColumnWidth.DECIMAL,
          type: ValueType.Number,
          style: { numFmt: '0.000000000000000###############' },
          allowPunctuation: true,
          dataValidation: {
            type: ECellTypes.CUSTOM,
            allowBlank: false,
            showErrorMessage: true,
            formulae: [],
            errorStyle: 'Error',
            showInputMessage: true,
          },
          isRequired: false,
          isDecimalNumber: true,
        },
        {
          header: this.translate.instant('main.workOrder.workOrderForm.isSplitted.label'),
          key: 'isSplitted',
          width: 45,
          type: ValueType.Boolean,
          dropdownOptions: {
            data: booleanDropdownOptions,
            prop: 'name',
            dataProperty: 'isSplittedDropdown.name',
            dataId: 'isSplittedDropdown.id',
          },
          dataValidation: {
            type: ECellTypes.LIST,
            allowBlank: false,
            showErrorMessage: true,
            formulae: [],
            errorStyle: 'Error',
            showInputMessage: true,
          },
          isRequired: false,
        },
      ],
    };

    if (sites.length && !sites[0]?.menuPermissions?.modules?.scheduler) {
      _.remove(workOrderExcelColumns.columns, { key: 'isSplitted' });
    }

    if (withData && withErrorColumn !== true) {
      workOrderExcelColumns.columns.push(
        {
          header: this.translate.instant('excel.workOrderSchedule.packageSize.header'),
          key: 'packageSize',
          width: EExcelColumnWidth.DEFAULT,
          type: ValueType.Number,
          style: { numFmt: '0' },
          maxLength: 11,
          allowPunctuation: true,
          isReadOnly: true,
          dataValidation: {
            type: ECellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('workOrderSchedule.listView.headers.lastDepartment'),
          key: 'lastDepartmentTitle',
          width: EExcelColumnWidth.DEFAULT,
          type: ValueType.String,
          style: { numFmt: '@' },
          isReadOnly: true,
          dataValidation: {
            type: ECellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('workOrderSchedule.listView.headers.lastLine'),
          key: 'lastLineTitle',
          width: EExcelColumnWidth.DEFAULT,
          type: ValueType.String,
          style: { numFmt: '@' },
          isReadOnly: true,
          dataValidation: {
            type: ECellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('excel.workOrderSchedule.actualRunDate.header'),
          key: 'actualRunDate',
          width: EExcelColumnWidth.DEFAULT,
          type: ValueType.String,
          style: { numFmt: '@' },
          isReadOnly: true,
          dataValidation: {
            type: ECellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('excel.workOrderSchedule.actualEndDate.header'),
          key: 'actualEndDate',
          width: EExcelColumnWidth.DEFAULT,
          type: ValueType.String,
          style: { numFmt: '@' },
          isReadOnly: true,
          dataValidation: {
            type: ECellTypes.CUSTOM,
          },
        },
        ...(isLinePathUtilizedOnSite
          ? [
              {
                header: this.translate.instant('general.dataTable.header.assignedLinePath'),
                key: 'assignedLinePathName',
                width: EExcelColumnWidth.DEFAULT,
                type: ValueType.String,
                style: { numFmt: '@' },
                isReadOnly: true,
                dataValidation: {
                  type: ECellTypes.CUSTOM,
                },
              } as ICreateExcel['columns'][number],
            ]
          : []),
        ...(isLinePathUtilizedOnSite
          ? [
              {
                header: this.translate.instant('general.dataTable.header.assignedLineIdWithinLinePath'),
                key: 'assignedLineWithinLinePathTitle',
                width: EExcelColumnWidth.DEFAULT,
                type: ValueType.String,
                style: { numFmt: '@' },
                isReadOnly: true,
                dataValidation: {
                  type: ECellTypes.CUSTOM,
                },
              } as ICreateExcel['columns'][number],
            ]
          : []),
        {
          header: this.translate.instant('excel.workOrderSchedule.completed.header'),
          key: 'completed',
          width: EExcelColumnWidth.DEFAULT,
          type: ValueType.String,
          style: { numFmt: '@' },
          isReadOnly: true,
          dataValidation: {
            type: ECellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('excel.workOrderSchedule.oeeGoodCount.header'),
          key: 'oeeGoodCount',
          width: EExcelColumnWidth.DEFAULT,
          type: ValueType.Number,
          style: { numFmt: '0' },
          maxLength: 11,
          allowPunctuation: true,
          isReadOnly: true,
          dataValidation: {
            type: ECellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('excel.workOrderSchedule.oeeScrapCount.header'),
          key: 'oeeScrapCount',
          width: EExcelColumnWidth.DEFAULT,
          type: ValueType.Number,
          style: { numFmt: '0' },
          maxLength: 11,
          allowPunctuation: true,
          isReadOnly: true,
          dataValidation: {
            type: ECellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('excel.workOrderSchedule.oeeInitialCount.header'),
          key: 'oeeInitialCount',
          width: EExcelColumnWidth.DEFAULT,
          type: ValueType.Number,
          style: { numFmt: '0' },
          maxLength: 11,
          allowPunctuation: true,
          isReadOnly: true,
          dataValidation: {
            type: ECellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('workOrderSchedule.listView.headers.missingDataNumber'),
          key: 'missingDataCount',
          width: EExcelColumnWidth.DEFAULT,
          type: ValueType.Number,
          style: { numFmt: '0' },
          maxLength: 11,
          allowPunctuation: true,
          isReadOnly: true,
          dataValidation: {
            type: ECellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('excel.workOrderSchedule.oeeTotalRunDuration.header'),
          key: 'oeeTotalRunDuration',
          width: EExcelColumnWidth.DEFAULT,
          type: ValueType.Number,
          style: { numFmt: '0' },
          maxLength: 11,
          allowPunctuation: true,
          isReadOnly: true,
          dataValidation: {
            type: ECellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('excel.workOrderSchedule.oeeTotalDownDuration.header'),
          key: 'oeeTotalDownDuration',
          width: EExcelColumnWidth.DEFAULT,
          type: ValueType.String,
          style: { numFmt: '@' },
          allowPunctuation: true,
          isReadOnly: true,
          dataValidation: {
            type: ECellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('excel.workOrderSchedule.oeeTotalIdleTimeDuration.header'),
          key: 'oeeTotalIdleTimeDuration',
          width: EExcelColumnWidth.DEFAULT,
          type: ValueType.String,
          style: { numFmt: '@' },
          allowPunctuation: true,
          isReadOnly: true,
          dataValidation: {
            type: ECellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('excel.workOrderSchedule.oeeOveralloee.header'),
          key: 'oeeOveralloee',
          width: EExcelColumnWidth.DEFAULT,
          type: ValueType.Number,
          style: { numFmt: '0' },
          maxLength: 11,
          allowPunctuation: true,
          isReadOnly: true,
          dataValidation: {
            type: ECellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('excel.workOrderSchedule.oeeA.header'),
          key: 'oeeA',
          width: EExcelColumnWidth.DEFAULT,
          type: ValueType.Number,
          style: { numFmt: '0' },
          maxLength: 11,
          allowPunctuation: true,
          isReadOnly: true,
          dataValidation: {
            type: ECellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('excel.workOrderSchedule.oeeP.header'),
          key: 'oeeP',
          width: EExcelColumnWidth.DEFAULT,
          type: ValueType.Number,
          style: { numFmt: '0' },
          maxLength: 11,
          allowPunctuation: true,
          isReadOnly: true,
          dataValidation: {
            type: ECellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('excel.workOrderSchedule.oeeQ.header'),
          key: 'oeeQ',
          width: EExcelColumnWidth.DEFAULT,
          type: ValueType.Number,
          style: { numFmt: '0' },
          maxLength: 11,
          allowPunctuation: true,
          isReadOnly: true,
          dataValidation: {
            type: ECellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('workOrderSchedule.listView.headers.productDescription'),
          key: 'productDescription',
          width: 40,
          type: ValueType.String,
          style: { numFmt: '@' },
          isReadOnly: true,
          dataValidation: {
            type: ECellTypes.CUSTOM,
          },
        },
      );
    }

    if (withErrorColumn) {
      workOrderExcelColumns.columns.push({
        header: this.translate.instant('excel.column.errorTitle'),
        key: 'errorMessages',
        width: EExcelColumnWidth.ERROR,
        type: ValueType.String,
        style: { numFmt: '@' },
        dataValidation: {
          type: ECellTypes.CUSTOM,
          allowBlank: false,
          formulae: [],
          showErrorMessage: false,
          error: this.translate.instant('general.error'),
          errorTitle: this.translate.instant('general.error'),
          errorStyle: 'Error',
          showInputMessage: false,
          prompt: this.translate.instant('general.error'),
          promptTitle: this.translate.instant('general.error'),
        },
      });
    }

    return workOrderExcelColumns;
  }

  public getWorkOrderCloseFeedback(params: HttpParams): Observable<WorkOrderCloseFeedbackResponseInterface> {
    return this.http.get<WorkOrderCloseFeedbackResponseInterface>(`${this.api}${this.routes.workOrderCloseFeedback}`, {
      params,
    });
  }

  public updateWorkOrderCloseFeedback(
    workOrderCloseFeedbackData: WorkOrderCloseFeedbackInterface,
  ): Observable<WorkOrderCloseFeedbackResponseInterface> {
    return this.http.patch<WorkOrderCloseFeedbackResponseInterface>(
      `${this.api}${this.routes.workOrderCloseFeedback}/${workOrderCloseFeedbackData.id}`,
      {
        workOrderId: workOrderCloseFeedbackData.workOrderId,
        crewPerformPoint: workOrderCloseFeedbackData.crewPerformPoint,
        goodThings: workOrderCloseFeedbackData.goodThings,
        needToBeImprove: workOrderCloseFeedbackData.needToBeImprove,
        variancesExplanation: workOrderCloseFeedbackData.variancesExplanation,
      },
    );
  }

  public saveWorkOrderCloseFeedback(
    workOrderCloseFeedbackData: WorkOrderCloseFeedbackInterface,
  ): Observable<WorkOrderCloseFeedbackResponseInterface> {
    return this.http.post<WorkOrderCloseFeedbackResponseInterface>(`${this.api}${this.routes.workOrderCloseFeedback}`, {
      workOrderId: workOrderCloseFeedbackData.workOrderId,
      crewPerformPoint: workOrderCloseFeedbackData.crewPerformPoint,
      goodThings: workOrderCloseFeedbackData.goodThings,
      needToBeImprove: workOrderCloseFeedbackData.needToBeImprove,
      variancesExplanation: workOrderCloseFeedbackData.variancesExplanation,
    });
  }

  public assignWoLinePathJob(
    workOrderId: number,
    linePathId: number,
    homeLineId: number,
  ): Observable<GetManyResponseInterface<number>> {
    return this.http.post<GetManyResponseInterface<number>>(
      `${this.api}${this.routes.workOrders}${this.routes.assignWoLinePathJob}`,
      {
        workOrderId,
        linePathId,
        homeLineId,
      },
    );
  }

  public getCustomWorkOrdersNewDesign(params: HttpParams): Observable<WorkOrderResponseInterface> {
    const url = `${this.api}${this.routes.customWorkOrders}`;
    return this.http.get<WorkOrderResponseInterface>(`${url}?${params}`);
  }

  public getCustomWorkOrders(
    filters: CustomWorkOrdersFilterOutputInterface | CustomNewDesignWorkOrdersFilterOutputInterface,
    query: TableQueryParams = {
      page: 1,
      pageSize: 10,
    },
  ): Observable<FormattedWorkOrdersResponseInterface> {
    const url = `${this.api}${this.routes.customWorkOrders}`;
    const customSearchString = this.prepareCustomWorkOrderFilters(filters, query);

    return this.http.get<FormattedWorkOrdersResponseInterface>(`${url}?${customSearchString}`);
  }

  public prepareCustomWorkOrderFilters(
    filters: CustomWorkOrdersFilterOutputInterface | CustomNewDesignWorkOrdersFilterOutputInterface,
    query: TableQueryParams = {
      page: 1,
      pageSize: 10,
    },
  ): string {
    const search: string[] = [`offset=${query.page}`, `limit=${query.pageSize}`];
    this.setWorkOrderFilters(filters, search);

    let searchString = '';
    search.forEach((params: string) => (searchString += `${params}&`));

    if (query.hasOwnProperty('orderType') && query.hasOwnProperty('order')) {
      searchString += `order=${query.order}&orderType=${query.orderType}`;
    } else {
      searchString += 'order=id&orderType=DESC';
    }

    return searchString;
  }

  public setWorkOrderFilters(
    filters: CustomWorkOrdersFilterOutputInterface | CustomNewDesignWorkOrdersFilterOutputInterface,
    search: string[],
  ): void {
    Object.keys(filters).forEach((key) => {
      switch (key) {
        case CustomDependFilterKeysEnum.COMPLETE_STATUS:
          const workOrderStatus = _.get(filters, key, null);

          if (workOrderStatus !== -1 && workOrderStatus !== null) {
            workOrderStatus.forEach((status) => {
              const workOrderStatusConst = _.get(workOrderStatusConstant, status, null);
              search.push(`${key}=${workOrderStatusConst.key}`);
            });
          }
          break;
        case CustomDependFilterKeysEnum.FILTER_DATE_TYPE:
          const filterDateType = _.get(filters, 'filterDateType', null);
          const filterStartDate: Moment = _.get(filters, 'filterStartDate', null);
          const filterEndDate: Moment = _.get(filters, 'filterEndDate', null);
          const dateTypeConst = _.get(dateTypesConstant, filterDateType, null);

          if (dateTypeConst !== null && dateTypeConst !== -1 && filterStartDate !== null && filterEndDate !== null) {
            search.push(`${key}=${filterDateType}`);
            search.push(`${CustomDependFilterKeysEnum.FILTER_START_DATE}=${filterStartDate}`);
            search.push(`${CustomDependFilterKeysEnum.FILTER_END_DATE}=${filterEndDate}`);
          }
          break;
        case CustomDependFilterKeysEnum.SEARCH_TYPE:
          const searchType = _.get(filters, 'searchType', null);
          const searchBy = _.get(filters, 'search', null);
          const searchTypeConst = _.get(workOrderPropertyConstant, searchType, null);

          if (
            searchTypeConst !== null &&
            searchTypeConst !== -1 &&
            searchBy !== null &&
            searchBy !== -1 &&
            searchBy !== ''
          ) {
            search.push(`${CustomDependFilterKeysEnum.SEARCH_TYPE}=${searchTypeConst.entityName}`);
            search.push(`${CustomDependFilterKeysEnum.SEARCH}=${searchBy}`);
          }
          break;
        case CustomDependFilterKeysEnum.SEARCH:
        case CustomDependFilterKeysEnum.FILTER_DATE_RANGE:
        case CustomDependFilterKeysEnum.FILTER_START_DATE:
        case CustomDependFilterKeysEnum.FILTER_END_DATE:
          break;
        default:
          const searchValue = _.get(filters, key, null);
          if (
            searchValue !== undefined &&
            searchValue !== null &&
            searchValue !== -1 &&
            searchValue !== '' &&
            searchValue.length !== 0
          ) {
            search.push(`${key}=${searchValue}`);
          }
          break;
      }
    });
  }

  public formatExcelValues(excelData: WorkOrderInterface[] = []): WorkOrderInterface[] {
    const inProgress = this.translate.instant('filterCard.workOrderStatus.inProgress');
    const completed = this.translate.instant('filterCard.workOrderStatus.completed');
    const notStarted = this.translate.instant('workOrder.completeStatus.notStarted');
    const canceled = this.translate.instant('workOrder.completeStatus.canceled');

    excelData.forEach((data: WorkOrderInterface) => {
      data.completed =
        data.canceled === 1
          ? canceled
          : data.completed === 1
          ? completed
          : String(data.inProgress) === '1'
          ? inProgress
          : notStarted;
      data.oeeTotalRunDuration = minutesToHm(_.toInteger(data.oeeTotalRunDuration));
      data.oeeTotalDownDuration = minutesToHm(_.toInteger(data.oeeTotalDownDuration));
      data.oeeTotalIdleTimeDuration = minutesToHm(_.toInteger(data.oeeTotalIdleTimeDuration));
      data.oeeOveralloee = `${data.oeeOveralloee}%`;
      data.oeeA = `${data.oeeA}%`;
      data.oeeP = `${data.oeeP}%`;
      data.oeeQ = `${data.oeeQ}%`;
      data.scheduledPostDuration = this.decimalHelper.removeTrailingZeros(data.scheduledPostDuration);
      data.scheduledPreDuration = this.decimalHelper.removeTrailingZeros(data.scheduledPreDuration);
      data.scheduledRunDuration = this.decimalHelper.removeTrailingZeros(data.scheduledRunDuration);
      data.scheduledPostRunLaborHours = this.decimalHelper.removeTrailingZeros(data.scheduledPostRunLaborHours);
      data.scheduledPreRunLaborHours = this.decimalHelper.removeTrailingZeros(data.scheduledPreRunLaborHours);
      data.scheduledRunLaborHours = this.decimalHelper.removeTrailingZeros(data.scheduledRunLaborHours);
      data.isSplittedDropdown =
        data.isSplitted !== null
          ? {
              id: String(data.isSplitted),
              name: this.translate.instant(`general.${data.isSplitted ? 'yes' : 'no'}`),
            }
          : [];
      data.scheduledRunDate = this.helperService.convertFromISOFormatToGivenTimezone(data.scheduledRunDate);
      data.scheduledEndDate = this.helperService.convertFromISOFormatToGivenTimezone(data.scheduledEndDate);
      data.woDueDate = this.helperService.convertFromISOFormatToGivenTimezone(data.woDueDate);
      data.actualRunDate = this.helperService.convertFromISOFormatToGivenTimezone(data.actualRunDate);
      data.actualEndDate = this.helperService.convertFromISOFormatToGivenTimezone(data.actualEndDate);
    });
    return excelData;
  }

  public loadUnplannedWorkOrderData(filters: ScenarioWorkOrdersTableQueryParams): Observable<WorkOrderInterface[]> {
    const url: string = `${this.api}${this.routes.workOrders}`;
    const whiteUnplannedWorkOrderSettings: {
      params: HttpParams;
      searchString: string;
    } = this.getUnplannedWorkOrderRequestSettings(filters, true);
    const unplannedWorkOrderSettings: {
      params: HttpParams;
      searchString: string;
    } = this.getUnplannedWorkOrderRequestSettings(filters);
    const observables: {
      whiteUnplannedWorkOrder: Observable<FormattedWorkOrdersResponseInterface>;
      unplannedWorkOrder: Observable<FormattedWorkOrdersResponseInterface>;
    } = {
      whiteUnplannedWorkOrder: this.http.get<FormattedWorkOrdersResponseInterface>(
        `${url}?${whiteUnplannedWorkOrderSettings.searchString}`,
        {
          params: whiteUnplannedWorkOrderSettings.params,
        },
      ),
      unplannedWorkOrder:
        filters.advancedFilter !== null
          ? this.http.get<FormattedWorkOrdersResponseInterface>(`${url}?${unplannedWorkOrderSettings.searchString}`, {
              params: unplannedWorkOrderSettings.params,
            })
          : of(null),
    };

    return forkJoin(observables).pipe(
      map(
        (resolvedObservables: {
          whiteUnplannedWorkOrder: FormattedWorkOrdersResponseInterface;
          unplannedWorkOrder: FormattedWorkOrdersResponseInterface | null;
        }) => {
          const formattedWhiteUnplannedWorkOrders: FormattedWorkOrdersResponseInterface =
            this.schedulerHelperService.formatResponse(
              this.responseSuitableForDecimalFormatting(resolvedObservables.whiteUnplannedWorkOrder),
            );
          const formattedUnplannedWorkOrder: FormattedWorkOrdersResponseInterface | null =
            resolvedObservables.unplannedWorkOrder
              ? this.schedulerHelperService.formatResponse(
                  this.responseSuitableForDecimalFormatting(resolvedObservables.unplannedWorkOrder),
                )
              : null;

          return formattedWhiteUnplannedWorkOrders.data.concat(formattedUnplannedWorkOrder?.data ?? []);
        },
      ),
    );
  }

  public workOrderMissingControl(
    params: IWorkOrderControlParams,
  ): Observable<BaseOneResponseInterface<IWorkOrderControlResponse>> {
    const httpParams: HttpParams = new HttpParams().set('siteId', params.siteId).set('lineId', params.lineId);
    return this.http.get<BaseOneResponseInterface<IWorkOrderControlResponse>>(
      `${this.api}${this.routes.workOrders}/${params.workOrderId}/${this.routes.workOrderMissingControl}`,
      { params: httpParams },
    );
  }

  public formatResponse(
    response: WorkOrderResponseInterface,
    isForExcel: boolean = false,
  ): FormattedWorkOrdersResponseInterface {
    let { rows, phaseNames, ...counts } = response.data;

    const columnNames = phaseNames;
    rows = rows.map((workOrder: WorkOrderInterface) => ({
      ...workOrder,
      scheduledPostRunLaborHours: workOrder.scheduledPostRunLaborHours
        ? isForExcel
          ? this.decimalHelper.removeTrailingZeros(
              this.decimalHelper.replaceDecimalSeparator(workOrder.scheduledPostRunLaborHours, '.'),
            )
          : this.decimalHelper.toFixedValue(workOrder.scheduledPostRunLaborHours)
        : null,
      scheduledPreRunLaborHours: workOrder.scheduledPreRunLaborHours
        ? isForExcel
          ? this.decimalHelper.removeTrailingZeros(
              this.decimalHelper.replaceDecimalSeparator(workOrder.scheduledPreRunLaborHours, '.'),
            )
          : this.decimalHelper.toFixedValue(workOrder.scheduledPreRunLaborHours)
        : null,
      scheduledRunLaborHours: workOrder.scheduledRunLaborHours
        ? isForExcel
          ? this.decimalHelper.removeTrailingZeros(
              this.decimalHelper.replaceDecimalSeparator(workOrder.scheduledRunLaborHours, '.'),
            )
          : this.decimalHelper.toFixedValue(workOrder.scheduledRunLaborHours)
        : null,
      scheduledPreDuration: isForExcel
        ? this.decimalHelper.removeTrailingZeros(
            this.decimalHelper.replaceDecimalSeparator(workOrder.scheduledPreDuration, '.'),
          )
        : workOrder.scheduledPreDuration,
      scheduledRunDuration: isForExcel
        ? this.decimalHelper.removeTrailingZeros(
            this.decimalHelper.replaceDecimalSeparator(workOrder.scheduledRunDuration, '.'),
          )
        : workOrder.scheduledRunDuration,
      scheduledPostDuration: isForExcel
        ? this.decimalHelper.removeTrailingZeros(
            this.decimalHelper.replaceDecimalSeparator(workOrder.scheduledPostDuration, '.'),
          )
        : workOrder.scheduledPostDuration,
      quantityOrdered: isForExcel
        ? this.decimalHelper.removeTrailingZeros(
            this.decimalHelper.replaceDecimalSeparator(workOrder.quantityOrdered, '.'),
          )
        : this.decimalHelper.toFixedValue(workOrder.quantityOrdered, workOrder.siteDecimalScaleLimit),
      oeeGoodCount: workOrder.oeeGoodCount
        ? isForExcel
          ? this.decimalHelper.removeTrailingZeros(
              this.decimalHelper.replaceDecimalSeparator(workOrder.oeeGoodCount, '.'),
            )
          : this.decimalHelper.toFixedValue(workOrder.oeeGoodCount, workOrder.siteDecimalScaleLimit)
        : null,
      oeeScrapCount: workOrder.oeeScrapCount
        ? isForExcel
          ? this.decimalHelper.removeTrailingZeros(
              this.decimalHelper.replaceDecimalSeparator(workOrder.oeeScrapCount, '.'),
            )
          : this.decimalHelper.toFixedValue(workOrder.oeeScrapCount, workOrder.siteDecimalScaleLimit)
        : null,
      oeeInitialCount: workOrder.oeeInitialCount
        ? isForExcel
          ? this.decimalHelper.removeTrailingZeros(
              this.decimalHelper.replaceDecimalSeparator(workOrder.oeeInitialCount, '.'),
            )
          : this.decimalHelper.toFixedValue(workOrder.oeeInitialCount, workOrder.siteDecimalScaleLimit)
        : null,
      oeeOveralloee: workOrder.oeeOveralloee
        ? this.decimalHelper.toFixedValue(this.decimalHelper.multiply(workOrder.oeeOveralloee, '100'), 2)
        : null,
      oeeA: workOrder.oeeA
        ? this.decimalHelper.toFixedValue(this.decimalHelper.multiply(workOrder.oeeA, '100'), 2)
        : null,
      oeeP: workOrder.oeeP
        ? this.decimalHelper.toFixedValue(this.decimalHelper.multiply(workOrder.oeeP, '100'), 2)
        : null,
      oeeQ: workOrder.oeeQ
        ? this.decimalHelper.toFixedValue(this.decimalHelper.multiply(workOrder.oeeQ, '100', 2))
        : null,
      minimumWaitingDuration: isForExcel
        ? this.decimalHelper.removeTrailingZeros(
            this.decimalHelper.replaceDecimalSeparator(workOrder.minimumWaitingDuration, '.'),
          )
        : this.decimalHelper.toFixedValue(workOrder.minimumWaitingDuration, workOrder.siteDecimalScaleLimit),
      maximumWaitingDuration: isForExcel
        ? this.decimalHelper.removeTrailingZeros(
            this.decimalHelper.replaceDecimalSeparator(workOrder.maximumWaitingDuration, '.'),
          )
        : this.decimalHelper.toFixedValue(workOrder.maximumWaitingDuration, workOrder.siteDecimalScaleLimit),
      woTargetSpeed: isForExcel
        ? this.decimalHelper.removeTrailingZeros(
            this.decimalHelper.replaceDecimalSeparator(workOrder.woTargetSpeed, '.'),
          )
        : this.decimalHelper.toFixedValue(workOrder.woTargetSpeed, workOrder.siteDecimalScaleLimit),
    }));

    return {
      ...response,
      ...counts,
      data: rows,
      columnNames,
    };
  }

  private getUnplannedWorkOrderRequestSettings(
    filters: ScenarioWorkOrdersTableQueryParams,
    isWhiteWorkOrderRequest: boolean = false,
  ): { params: HttpParams; searchString: string } {
    const newScenarioWorkOrderFilter: string = this.getScenarioSearchParams(filters, isWhiteWorkOrderRequest);
    const searchString: string = this.prepareWorkorderFilters(
      newScenarioWorkOrderFilter,
      filters,
      isWhiteWorkOrderRequest,
    );
    let params: HttpParams = new HttpParams();

    if (!isWhiteWorkOrderRequest) {
      params = params.set('siteId', filters.siteId);
      params = params.set('scenarioId', filters.scenarioId);
    }

    return { params, searchString };
  }

  public responseSuitableForDecimalFormatting(
    workOrders: FormattedWorkOrdersResponseInterface,
  ): WorkOrderResponseInterface {
    return {
      ...workOrders,
      data: {
        rows: workOrders.data,
        notStartedCount: workOrders.notStartedCount,
        inProgressCount: workOrders.inProgressCount,
        completedCount: workOrders.completedCount,
      },
    };
  }

  private getSitesForExcel(siteId: number): Observable<SitesInterface> {
    const params: HttpParams = this.httpHelper.insertGenericCrudRequestParameters({
      join: ['linePaths', 'linePaths.linePathProductConfigurations', 'linePaths.floorPlan||id,name'],
    });
    return this.siteService.getOneSite(params, siteId).pipe(
      map((response): SitesInterface => {
        const { isExcelDateTimeFormat, woDueDateExcelFormat, ...restOfTheFields } = response.data;

        return {
          ...restOfTheFields,
          columnSpecificDateFormats: {
            scheduledRunDate: isExcelDateTimeFormat ? EExcelDateFormatOption.Datetime : EExcelDateFormatOption.Date,
            scheduledEndDate: isExcelDateTimeFormat ? EExcelDateFormatOption.Datetime : EExcelDateFormatOption.Date,
            woDueDate: woDueDateExcelFormat ? EExcelDateFormatOption.Datetime : EExcelDateFormatOption.Date,
          },
        };
      }),
    );
  }
}
